import * as React from "react";

import OptionsWizard from "../OptionsWizard/OptionsWizard";
import ItemInfoContainer from "../MainWizard/ItemInfoContainer";

import { ISubLineItemInfo } from "../interfaces";
import { AltWizActionTypeEnum } from "../enum";

import PartSelector from "../WebDesigner/PartSelector";
import SectionSizing from "../WebDesigner/SectionSizing";
import useWizardState, { useWizardStateActions } from "components/OptionsWizard/WebDesigner/useWizardState";
import useFormatHelper from "helpers/hooks/useFormatHelper";
import { Grid, Box } from "@mui/material";
import useIsMobile from "helpers/hooks/useIsMobile";
import WizardBottomNav from "components/OptionsWizard/MainWizard/Mobile/WizardBottomNav";
import { PartDefaults } from "helpers/context/Parts/usePartDefaults";
import { IPartCallSizePresets } from "helpers/interfaces";
import { IItemInformation } from "components/OptionsWizard/ItemProperties/ItemProperties";
import PartsAPI from "helpers/PartsAPI";
import { WizardViewEnum } from "../WizardContext";
import useApplicationInfo from "helpers/context/Application/useApplicationInfo";
import useWait from "helpers/context/Page/useWait";


const AlternateWizardMain: React.FC<any> = () => {

    const wizardState = useWizardState();
    const wizardActions = useWizardStateActions();
    const format = useFormatHelper();
    const isMobile = useIsMobile();
    const appInfo = useApplicationInfo();
    const wait = useWait();

    const [partDefaults, setPartDefaults] = React.useState<PartDefaults | undefined>(undefined);
    const [partCallSizes, setPartCallSizes] = React.useState<IPartCallSizePresets | undefined>(undefined);

    const shouldShowSectionSizing = React.useMemo(() => {
        return wizardState.altWizInfo !== null && (wizardState.altWizInfo.currentAction !== null) && wizardState.altWizInfo.currentAction === AltWizActionTypeEnum.SectionSizing;
    }, [wizardState.altWizInfo]);

    const shouldShowPartSelector = React.useMemo(() => {
        return wizardState.altWizInfo !== null && (wizardState.altWizInfo.currentAction !== null) && wizardState.altWizInfo.currentAction === AltWizActionTypeEnum.SectionSelectPart;
    }, [wizardState.altWizInfo]);

    React.useEffect(() => {
        /*TODO: Cache this */
        if (wizardState && wizardState.itemInfo && shouldShowSectionSizing) {
            const partDefPromise = PartsAPI.GetPartDefaults(wizardState.itemInfo.partNo, wizardState.itemInfo.partNoSuffix, wizardState.itemInfo.shortcutName);
            const callSizesPromise = PartsAPI.GetPartCallSizes(wizardState.itemInfo.partNo, wizardState.itemInfo.partNoSuffix);

            Promise.all([partDefPromise, callSizesPromise]).then(([partDefaults, callSizes]) => {
                setPartCallSizes(callSizes);
                setPartDefaults(new PartDefaults(partDefaults));
            });
        } else {
            setPartCallSizes(undefined);
            setPartDefaults(undefined);
        }
    }, [wizardState, shouldShowSectionSizing]);

    const itemInformation = React.useMemo(() => {

        if (partCallSizes && partDefaults) {

            const itemInfo: IItemInformation = {
                existingItem: wizardState?.itemInfo?.odkey !== 0,
                isSubLineItem: (wizardState.webDesigner && wizardState.webDesigner.currentSection !== 0) ?? false,
                callSizes: partCallSizes,
                defaults: partDefaults
            };

            return itemInfo;

        }
        //If any itemInfo props change, we need to update this.
    }, [partCallSizes, partDefaults, wizardState?.itemInfo, wizardState.webDesigner]);



    React.useEffect(() => {
        wait.Show(wizardState.loading);
    }, [wait, wizardState.loading]);

    React.useEffect(() => {
        if (appInfo.parameters.autoUseProductNavigatorInAlternateWizard && wizardState.altWizInfo?.currentAction === AltWizActionTypeEnum.SectionSelectPart) {
            wizardActions?.SwitchToView(WizardViewEnum.ProductNavigator);
        }
        // Note the SelectedItems being part of the dependency array. This is to avoid an edge case during auto-prompting for a template
        //      when a part with no options is selected, but there are more sections to fill. So when the selected items change, we go to Product Navigator again
    }, [appInfo, wizardActions, wizardState.altWizInfo?.currentAction, wizardState.altWizInfo?.selectedItems])

    const getFirstSelectedSubLineItem = () => {
        if (wizardState.webDesigner && wizardState.webDesigner.sectionList && wizardState.altWizInfo) {
            let firstSLI: number = wizardState.altWizInfo.selectedItems[0];

            for (var i = 0; i < wizardState.webDesigner.sectionList.length; i++) {
                if (wizardState.webDesigner.sectionList[i].sli === firstSLI) {
                    return wizardState.webDesigner.sectionList[i];
                }
            }
        }

        return null;
    }

    const shouldShowOptions = React.useMemo(() => {
        if (wizardState.altWizInfo && (wizardState.altWizInfo.currentAction != null)) {
            switch (wizardState.altWizInfo.currentAction) {
                case AltWizActionTypeEnum.MainLineItemOptions:
                case AltWizActionTypeEnum.SectionOptions:
                    return true;
                default:
                    return false;
            }
        }

        return false;
    }, [wizardState.altWizInfo]);

    let currentSLI: ISubLineItemInfo | null = getFirstSelectedSubLineItem();

    if (wizardState.wizardView !== WizardViewEnum.Wizard) {
        // This needs to still be mounted even when the UI doesn't show, so that remounting the component does not reinitialize it
        // Reinitializing the component without completing or canceling it causes problems
        return null;
    }

    if (isMobile && shouldShowOptions) {
        return <>
            <Grid container sx={{ position: "relative" }}>
                <WizardBottomNav />
            </Grid>
        </>;
    }

    return <Grid container direction={isMobile ? "column" : "row"} width="100%">

        {/*Left Pane*/}
        <Grid item xs={isMobile ? 1 : 8}>
            <Box mt={2} mx={1}>
                {shouldShowPartSelector && wizardState.itemInfo !== null && currentSLI !== null &&
                    <PartSelector itemInfo={wizardState.itemInfo} sliInfo={currentSLI} mode={wizardState.wizardMode} />
                }

                {shouldShowOptions &&
                    <OptionsWizard
                        groups={wizardState.groups}
                        userPreferences={wizardState.userPreferences}
                        wizardKey={wizardState.wizardStateKey}
                        readOnlyMode={wizardState.sysInfo != null && wizardState.sysInfo.readOnlyMode}
                        itemInfo={wizardState.itemInfo}
                    />
                }

                {shouldShowSectionSizing && wizardState.itemInfo !== null && wizardState.altWizInfo !== null && currentSLI !== null && itemInformation &&
                    <SectionSizing
                        sliInfo={currentSLI}
                        itemInfo={itemInformation}
                    />
                }
            </Box>
        </Grid>

        {/*Right Pane*/}
        {((wizardState.altWizInfo || wizardState.webDesigner)) &&
            <Grid item xs>
                <Box width={isMobile ? 1 / 2 : 1} m={"0 auto"}>
                    <ItemInfoContainer
                        options={wizardState.options}
                        partPreferences={wizardState.partPreferences}
                        validations={wizardState.validations}
                        itemInfo={wizardState.itemInfo}
                        webDesigner={wizardState.webDesigner}
                        displayViewSettings={wizardState.displayViewSettings}
                        focusedCode={wizardState.focusedCode}
                        prices={wizardState.prices}
                        altWizInfo={wizardState.altWizInfo}
                        mode={wizardState.wizardMode}
                        format={format}
                    />
                </Box>
            </Grid>
        }
    </Grid>

}

export default AlternateWizardMain;

