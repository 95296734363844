import * as React from "react";
import { ILineItemInfo, IWebDesigner, ISubLineItemInfo, IKeyValuePair } from "../interfaces";
import { useTranslations } from "@fenetech/translations";
import { Grid, Typography } from "@mui/material";
import ItemPropertiesDialog from "components/OptionsWizard/ItemProperties/ItemPropertiesDialog";
import { IItemProperties } from "components/OptionsWizard/ItemProperties/ItemProperties";
import ItemSizeLabel from "components/OptionsWizard/MainWizard/ItemSizeLabel";
import { DesignerToolsEnum } from "components/OptionsWizard/enum";
import ODOpeningPropertiesDialog from "components/OptionsWizard/ItemProperties/ODOpeningPropertiesDialog";
import { IODOpeningProperties } from "components/OptionsWizard/ItemProperties/ODOpeningProperties";
import { IODSLIProperties } from "components/OptionsWizard/ItemProperties/ODSLIProperties";
import ODSLIPropertiesDialog from "components/OptionsWizard/ItemProperties/ODSLIPropertiesDialog";
import useWizardState from "components/OptionsWizard/WebDesigner/useWizardState";
import useFormatHelper from "helpers/hooks/useFormatHelper";
import useWizardInteractions from "../useWizardInteractions";



interface IProps {
}

enum PropertiesDialogTypeEnum {
    ItemProperties,
    ODOpeningProperties,
    ODSLIProperties,
}

const shouldShowDesignerEdit = (webDesigner: IWebDesigner | null): boolean => {
    if (!webDesigner)
        //Not a web designer, will show the standard Edit
        return false;
    else if (webDesigner.supportsSLI) {
        //This is BBW or OD, look at the main vs subline item settings
        return ((webDesigner.currentSection === 0 && webDesigner.canChangeMLISize) ||
            (webDesigner.currentSection !== 0 && webDesigner.canChangeSLISize))
    }
    else {
        //This is Showers, always show Edit (although the size will be disabled)
        return true;
    }
}

const ItemSize: React.FC<IProps> = (props: IProps) => {

    const wizardState = useWizardState();
    const format = useFormatHelper();
    const wizardInteractions = useWizardInteractions();
    
    let showSizeEdit: boolean = true;
    let showSize: boolean = true;
    let showComment: boolean = true;
    let currentSLI: ISubLineItemInfo | undefined;

    const [dialogVisible, setDialogVisible] = React.useState<boolean>(false);

    const dialogType = React.useMemo<PropertiesDialogTypeEnum>(() => {

        if (wizardState.webDesigner && shouldShowDesignerEdit(wizardState.webDesigner) && wizardState.webDesigner.supportsSLI) {
            let webDesigner: IWebDesigner = wizardState.webDesigner;

            //Webdesigner sizing dialog
            let currentInfo: ISubLineItemInfo | undefined = webDesigner.sectionList.find((sli) => sli.sli === webDesigner.currentSection);
            let supportedTools: DesignerToolsEnum = currentInfo?.tools ?? 0;

            if (webDesigner.currentSection === 0 && webDesigner.canChangeSLISize) {
                return PropertiesDialogTypeEnum.ODOpeningProperties;
            }
            else if ((supportedTools && DesignerToolsEnum.ODSLIProperties) > 0) {
                return PropertiesDialogTypeEnum.ODSLIProperties;
            }
        }

        return PropertiesDialogTypeEnum.ItemProperties;

    }, [wizardState.webDesigner]);


    const tm = useTranslations();


    const handleEditClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setDialogVisible(true)
    }

    const handleItemPropertiesSubmit = (oldState: IItemProperties, newState: IItemProperties) => {
        wizardInteractions.ChangeItemPropertiesAsync(newState).then(() => {
            setDialogVisible(false);
        });
    };

    const handleODOpeningPropertiesSubmit = (oldState: IODOpeningProperties, newState: IODOpeningProperties) => {
        wizardInteractions.ChangeODOpeningPropertiesAsync(newState).then(() => {
            setDialogVisible(false);
        });
    }

    const handleODSLIPropertiesSubmit = async (oldState: IODSLIProperties, newState: IODSLIProperties) => {

        const responseMessage = await wizardInteractions.ChangeODSLIPropertiesAsync(newState);

        if (responseMessage) {
            return responseMessage;
        } else {
            setDialogVisible(false);
            return null;
        }

    }


    if (!wizardState.itemInfo || !format) {
        return null;
    } else {

        if (wizardState.webDesigner) {
            let wd: IWebDesigner = wizardState.webDesigner;

            showComment = (!wd || wd.currentSection === 0); //Only show comment form Main LI for designers

            showSize = true;
            showSizeEdit = shouldShowDesignerEdit(wd);
            if (wd !== null) {
                currentSLI = wd.sectionList && wd.sectionList.find((sli: ISubLineItemInfo) => sli.sli === wd.currentSection);
            }
        }

        let ii: ILineItemInfo = wizardState.itemInfo;

        return <Grid container direction="column" alignItems="stretch" spacing={1}  >

            <ItemSizeLabel
                ii={ii}
                showSize={showSize}
                showSizeEdit={showSizeEdit}
                handleEditClick={handleEditClick}
                format={format}
            />

            {currentSLI && currentSLI.itemProperties && currentSLI.itemProperties.map((p: IKeyValuePair) =>
                <Grid item container direction="row" spacing={1} key={p.key}>
                    <Grid item xs="auto"><Typography >{p.key && tm.Get(p.key) + ":"}</Typography></Grid>
                    <Grid item xs> <Typography>{p.value}</Typography></Grid>
                </Grid>
            )}

            {showComment &&
                <Grid item container direction="row" spacing={1}>
                    <Grid item><Typography>{tm.Get("Item Comment:")}</Typography></Grid>
                    <Grid item><Typography>{wizardState.itemInfo.comment}</Typography></Grid>
                </Grid>
            }

            {dialogType === PropertiesDialogTypeEnum.ItemProperties &&
                <ItemPropertiesDialog
                    isSubLineItem={(wizardState.webDesigner && wizardState.webDesigner.currentSection !== 0) ?? false}
                    itemInfo={wizardState.itemInfo}
                    webDesigner={wizardState.webDesigner}
                    dialogVisible={dialogVisible}
                    onCancel={() => { setDialogVisible(false); }}
                    onSubmit={handleItemPropertiesSubmit}
                />
            }

            {dialogType === PropertiesDialogTypeEnum.ODOpeningProperties &&
                <ODOpeningPropertiesDialog
                    dialogVisible={dialogVisible}
                    itemInfo={wizardState.itemInfo}
                    webDesigner={wizardState.webDesigner}
                    onCancel={() => { setDialogVisible(false); }}
                    onSubmit={handleODOpeningPropertiesSubmit}
                />
            }

            {dialogType === PropertiesDialogTypeEnum.ODSLIProperties &&
                <ODSLIPropertiesDialog
                    dialogVisible={dialogVisible}
                    itemInfo={wizardState.itemInfo}
                    webDesigner={wizardState.webDesigner}
                    onCancel={() => { setDialogVisible(false); }}
                    onSubmit={handleODSLIPropertiesSubmit}
                />
            }

        </Grid >;
    }

}

export default ItemSize;
