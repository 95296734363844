import { AssignmentLate, InsertPhoto, List, Paid } from "@mui/icons-material";
import { BottomNavigation, BottomNavigationAction, Container, Grid, Stack } from "@mui/material";
import useOptionHash from "components/OptionsWizard/GenericComponents/useOptionHash";
import { ISectionRegion } from "components/OptionsWizard/interfaces";
import ItemValidation from "components/OptionsWizard/MainWizard/ItemValidation";
import PricingLabel from "components/OptionsWizard/MainWizard/PricingLabel";
import OptionPreview from "components/OptionsWizard/MainWizard/OptionPreview";
import PricingSummary from "components/OptionsWizard/MainWizard/PricingSummary";
import OptionsWizard from "components/OptionsWizard/OptionsWizard/OptionsWizard";
import useWizardState from "components/OptionsWizard/WebDesigner/useWizardState";
import WebDesignerHeader from "components/OptionsWizard/WebDesigner/WebDesignerHeader";
import { WizardModeEnum } from "components/OptionsWizard/WizardContext";
import useQuoteData from "components/Quotes/useQuoteData";
import useFooterContent from "helpers/context/Page/useFooterContent";
import useFormatHelper from "helpers/hooks/useFormatHelper";
import { useTranslations, ITranslationManager } from "@fenetech/translations";
import React from 'react';
import ObjectViewSelector from "components/OptionsWizard/ObjectViewer/ObjectViewSelector";
import WizardHelper from "components/OptionsWizard/WizardHelper";

interface IProps {
    clickRegionHandler?: (clickableReg: ISectionRegion, tm: ITranslationManager) => void;
}


enum MobileViewEnum {
    Options,
    Image,
    Summary,
    Pricing,
    Validations
}


const WizardBottomNav: React.FC<IProps> = ({ clickRegionHandler }: IProps) => {

    const state = useWizardState();
    const { quote } = useQuoteData();

    const tm = useTranslations();
    const format = useFormatHelper();

    const [selectedAction, setSelectedAction] = React.useState<MobileViewEnum>(MobileViewEnum.Options);
    const footerRef = React.useRef<HTMLDivElement>(null);
    const [hasObjectViewerImage, setHasObjectViewerImage] = React.useState<boolean>(false);

    const optionHash = useOptionHash(state);
    const subLineItem = state.webDesigner?.currentSection ?? null;

    //We don't want to show the web designer header if we are currently going through the sequenced alt wiz
    const showWebDesignerHeader: boolean = !state?.altWizInfo

    const [optionWizardOffset, setOptionWizardOffset] = React.useState<number>(0);

    const onChangeHandler = React.useCallback((event: React.SyntheticEvent<Element, Event>, value: MobileViewEnum) => {
        if (selectedAction === MobileViewEnum.Options) {
            setOptionWizardOffset(window.scrollY);
        }

        setSelectedAction(value);

        setTimeout(function () {

            if (value === MobileViewEnum.Options) {
                window.scrollTo({
                    top: optionWizardOffset,
                    left: 0,
                    behavior: 'auto'
                });
            }
        }, 0)
    }, [selectedAction, setSelectedAction, optionWizardOffset]);

    const shouldShowPricingControl = React.useCallback(() => {
        if (state.prices && (state.wizardMode === WizardModeEnum.Standard || state.wizardMode === WizardModeEnum.SequencedWizard))
            return true;
        else
            return false;
    }, [state]);

    const shouldShowObjectViewSelector = React.useMemo(() => {
        if (selectedAction !== MobileViewEnum.Image || state.altWizInfo) {
            return false;
        }
        else if(state.focusedCode?.imageExists && WizardHelper.getMaximizeOptionImage()) {
            return false;
        } 
        return hasObjectViewerImage;
    }, [selectedAction, state.altWizInfo, state.focusedCode?.imageExists, hasObjectViewerImage]);    

    useFooterContent(<Grid ref={footerRef} item justifyContent="space-between"  >
        <Stack direction="column">
            {shouldShowObjectViewSelector &&
                <ObjectViewSelector />
            }
            <BottomNavigation
                value={selectedAction}
                onChange={onChangeHandler}
                showLabels={true}
                sx={{ width: "100%" }}
            >

                <BottomNavigationAction value={MobileViewEnum.Options} label={tm.Get("Options")} icon={<List />} />
                <BottomNavigationAction value={MobileViewEnum.Image} label={tm.Get("Image")} icon={<InsertPhoto />} />
                {shouldShowPricingControl() && <BottomNavigationAction value={MobileViewEnum.Pricing} label={<PricingLabel prices={state.prices} currencyCulture={quote?.currencyCulture ?? ""} hidePricingLabel />} icon={<Paid />} />}
                {state.validations && state.validations.messages.length > 0 && <BottomNavigationAction value={MobileViewEnum.Validations} label={tm.Get("Validations")} icon={<AssignmentLate />} />}

            </BottomNavigation>
        </Stack>
    </Grid>);

    return <>

        <Container maxWidth={false} sx={{ paddingTop: 1 }}>
            {selectedAction === MobileViewEnum.Options &&
                <>
                    {showWebDesignerHeader &&
                        <WebDesignerHeader
                            webDesigner={state.webDesigner}
                            validation={state.validations}
                            itemInfo={state.itemInfo}
                        />
                    }

                    < OptionsWizard
                        groups={state.groups}
                        userPreferences={state.userPreferences}
                        wizardKey={state.wizardStateKey}
                        readOnlyMode={state.sysInfo !== null && state.sysInfo.readOnlyMode}
                        itemInfo={state.itemInfo}
                    />
                </>
            }

            {selectedAction === MobileViewEnum.Image &&
                <OptionPreview
                    optionHash={optionHash}
                    partPreferences={state.partPreferences}
                    itemInfo={state.itemInfo}
                    webDesigner={state.webDesigner}
                    focusedCode={state.focusedCode}
                    altWizInfo={state.altWizInfo}
                    clickRegionHandler={clickRegionHandler}
                    mode={WizardModeEnum.Standard}
                    tm={tm}
                    format={format}
                    renderAsDiv={true}
                    setHasObjectViewerImage={setHasObjectViewerImage}
                />
            }

            {selectedAction === MobileViewEnum.Pricing && state.prices &&
                <PricingSummary
                    prices={state.prices}
                    subLineItem={subLineItem}
                    tm={tm}
                />
            }
            {selectedAction === MobileViewEnum.Validations && state.validations &&
                <ItemValidation validations={state.validations} />
            }
        </Container>
    </>

}

export default WizardBottomNav;