import { AltWizActionTypeEnum, PartValidationTypeEnum } from "components/OptionsWizard/enum";
import OptionsAPI from "components/OptionsWizard/OptionsAPI";
import useWizardState, { useWizardStateActions } from "components/OptionsWizard/WebDesigner/useWizardState";
import WizardAPI, { ILineItemSimple } from "components/OptionsWizard/WizardAPI";
import { WizardModeEnum, WizardViewEnum } from "components/OptionsWizard/WizardContext";
import QuoteNavigation from "components/Quotes/QuoteNavigation";
import useQuoteActions from "components/Quotes/useQuoteActions";
import useQuoteData from "components/Quotes/useQuoteData";
import useNavigationBlockerActions from "helpers/context/Application/useNavigationBlockerActions";
import { IActionButton } from "helpers/context/Page/PageContext";
import useActionButtons from "helpers/context/Page/useActionButtons";
import useMessageBox from "helpers/context/Page/useMessageBox";
import useWait from "helpers/context/Page/useWait";
import { ThemeColorEnum } from "helpers/enums";
import Format from "helpers/fv.format";
import { useTranslations } from "@fenetech/translations";
import React from "react";
import { useNavigate } from "react-router-dom";
import useWizardInteractions from "./useWizardInteractions";
import useConfiguratorInfo from "helpers/context/Configurator/useConfiguratorInfo";
import useConfiguratorHandler from "helpers/context/Configurator/useConfiguratorHandler";

export default function useWizardActionButtons(okey: number, odkey: number, commitAction: () => void, altPartAction: () => void, simpleLineItem: ILineItemSimple | null): void {

    const tm = useTranslations();
    const wizardState = useWizardState();
    const actionButtons = useActionButtons()
    const messagebox = useMessageBox();
    const wizardActions = useWizardStateActions();
    const quoteActions = useQuoteActions();
    const wait = useWait();
    const wizardInteractions = useWizardInteractions();
    const navBlockerActions = useNavigationBlockerActions();
    const configuratorInfo = useConfiguratorInfo();
    const configuratorHandler = useConfiguratorHandler();
    const navigate = useNavigate();

    const { productNavigatorState } = useQuoteData();

    const returnToMainWizard = React.useCallback(() => {
        wizardActions?.SwitchToStandard();
    }, [wizardActions]);

    const cancelWizard = React.useCallback(() => {
        if (simpleLineItem) {
            WizardAPI.ClearSessionAsync().then(() => {
                if (productNavigatorState.cameFromProductNavigator) {
                    quoteActions?.SetProductNavigatorState({ cameFromProductNavigator: false, finalNodeID: productNavigatorState.finalNodeID, callSize: productNavigatorState.callSize });
                    navigate(QuoteNavigation.ProductNavigatorURL(okey));
                } else {
                    if (configuratorInfo.configurator) {
                        configuratorHandler.PostOptionsWizardCancel(configuratorInfo.configurator);
                        window.open('about:blank', '_self');
                    } else {
                        navigate(QuoteNavigation.QuoteEntryURL(okey));
                    }
                }
            });
        }
    }, [quoteActions, productNavigatorState.cameFromProductNavigator, productNavigatorState.finalNodeID, productNavigatorState.callSize, simpleLineItem, navigate, configuratorInfo.configurator, configuratorHandler, okey]);

    const handleAlternatePartNo = React.useCallback(() => {
        wait.Show(true);

        WizardAPI.HandleAlternatePartNo().then(async r => {
            if (r.saved) {
                wait.Show(false);
                if (configuratorInfo.configurator) {
                    configuratorHandler.PostOptionsWizardSave(configuratorInfo.configurator, r.odKey!);
                    window.open('about:blank', '_self');
                } else {
                    quoteActions?.SetProductNavigatorState({ cameFromProductNavigator: false, finalNodeID: productNavigatorState.finalNodeID, callSize: productNavigatorState.callSize });
                    await quoteActions?.LoadQuoteAsync(okey);
                    navigate(QuoteNavigation.QuoteEntryURL(okey));
                }
            } else {
                console.log(r);
                throw new Error("Line item did not save for unknown reason.");
            }
        }).finally(() => {
            wait.Show(false);
        });
    }, [okey, productNavigatorState.callSize, productNavigatorState.finalNodeID, quoteActions, wait, navigate, configuratorInfo.configurator, configuratorHandler]);

    const handleAlternatePartYes = React.useCallback(() => {
        wait.Show(true);

        WizardAPI.HandleAlternatePartYes().then(r => {
            if (r === undefined) {
                wizardInteractions.RequeryFullStateAsync().then(() => {
                    altPartAction();
                });
            } else {
                console.log(r);
                throw new Error("Line item did not change for unknown reason.");
            }
        })
    }, [altPartAction, wizardInteractions, wait]);

    const addUpdateWizard = React.useCallback(() => {
        wait.Show(true);

        WizardAPI.SaveWizardAsync().then(async r => {
            if (r.saved) {
                wait.Show(false);
                if (configuratorInfo.configurator) {
                    configuratorHandler.PostOptionsWizardSave(configuratorInfo.configurator, r.odKey!);
                    window.open('about:blank', '_self');
                } else {
                    quoteActions?.SetProductNavigatorState({ cameFromProductNavigator: false, finalNodeID: productNavigatorState.finalNodeID, callSize: productNavigatorState.callSize });
                    await quoteActions?.LoadQuoteAsync(okey);
                    navigate(QuoteNavigation.QuoteEntryURL(okey));
                }
            } else if (r.canSave !== undefined) {
                wait.Show(false);
                messagebox.Show({ message: tm.Get("Validation errors exist. Cannot save."), title: tm.Get("Quote Entry") });

            } else if (r.sliThatAbortedSave !== undefined) {
                wait.Show(false);
                messagebox.Show({ message: tm.Get("Please correct errors before saving."), title: tm.Get("Quote Entry") });
                //Select the designer item that could not save
                OptionsAPI.SelectDesignerSectionAsync(r.sliThatAbortedSave);

            } else if (r.altPartNo !== undefined && r.altPartNoSuffix !== undefined) {
                wait.Show(false);
                var message = r.altPartReason + "\n" + tm.GetWithParams("Do you wish to use alternate part {0}?", Format.FormatPartDescription(r.altPartNo, r.altPartNoSuffix))
                messagebox.Show({
                    message, title: tm.Get("Quote Entry"), yesNoPrompt: true, callback: (result) => {
                        if (result) {
                            handleAlternatePartYes()
                        } else {
                            handleAlternatePartNo()
                        }
                    }
                })

            } else {

                console.log(r);
                throw new Error("Line item did not save for unknown reason.");
            }
        }).finally(() => {
            wait.Show(false);
        });

    }, [okey, productNavigatorState.callSize, productNavigatorState.finalNodeID, quoteActions, tm, wait, messagebox, handleAlternatePartNo, handleAlternatePartYes, navigate, configuratorInfo.configurator, configuratorHandler]);

    const submitShape = React.useCallback(() => {
        wizardInteractions.SelectShapeCodeWithParamsAsync().then(() => {
            wizardActions?.SwitchToView(WizardViewEnum.Wizard);
        });
    }, [wizardInteractions, wizardActions]);


    React.useEffect(() => {

        const validation = wizardState.validations;
        const addEnabled: boolean =
            (validation && simpleLineItem && (
                validation.canSave &&
                validation.requiredPartSubLineItems.length === 0 &&
                validation.requiredOptionSubLineItems.length === 0 &&
                validation.blockingValidationSubLineItems.length === 0 &&
                !validation.messages.some(m => m.type === PartValidationTypeEnum.Block))
            ) ?? false;

        const nextEnabled: boolean =
            (validation && simpleLineItem && (
                validation.canSave &&
                !validation.messages.some(m => m.type === PartValidationTypeEnum.Block))
            ) ?? false;

        actionButtons.Remove(0);
        actionButtons.Remove(1);

        if (wizardState.wizardView !== WizardViewEnum.Wizard) {
            let i = 0;
            if (wizardState.wizardView === WizardViewEnum.ShapeEditor) {
                const submitButton: IActionButton = {
                    text: tm.Get("Update"),
                    color: ThemeColorEnum.Secondary,
                    disabled: !wizardState.shapeEditorCanSave,
                    onClick: () => {
                        submitShape();
                        navBlockerActions.Unblock();
                    }
                }
                actionButtons.Set(0, submitButton);
                i = 1;
            }
            const cancelButton: IActionButton = {
                text: tm.Get("Cancel"),
                color: ThemeColorEnum.Primary,
                disabled: simpleLineItem === null,
                onClick: () => {
                    wizardActions?.SwitchToView(WizardViewEnum.Wizard);
                    navBlockerActions.Unblock();
                }
            }
            actionButtons.Set(i, cancelButton);
        } else {

            switch (wizardState.wizardMode) {
                case WizardModeEnum.Standard:

                    const cancelButton: IActionButton = {
                        text: tm.Get("Cancel"),
                        color: ThemeColorEnum.Primary,
                        disabled: simpleLineItem === null,
                        onClick: () => {
                            cancelWizard();
                            navBlockerActions.Unblock();
                        }
                    }

                    const addUpdateButton: IActionButton = {
                        text: tm.Get((odkey === 0 ? "Add" : "Save")),
                        color: ThemeColorEnum.Secondary,
                        disabled: !addEnabled,
                        onClick: () => {
                            addUpdateWizard();
                            navBlockerActions.Unblock();
                        }
                    }

                    actionButtons.Set(0, addUpdateButton);
                    actionButtons.Set(1, cancelButton);
                    break;

                case WizardModeEnum.SequencedWizard:

                    let canCommitAction: boolean = false;

                    switch (wizardState.altWizInfo?.currentAction) {
                        case AltWizActionTypeEnum.MainLineItemOptions:
                        case AltWizActionTypeEnum.SectionOptions:
                            canCommitAction = nextEnabled;
                            break;
                        case AltWizActionTypeEnum.SectionSelectPart:

                            const sli = wizardState.webDesigner?.sectionList.find(s => s.sli === wizardState.altWizInfo?.selectedItems[0])

                            if (sli?.requiresPart) {
                                if (sli.partNo && sli.partNoSuffix) {
                                    canCommitAction = true;
                                }

                            } else {
                                canCommitAction = true;
                            }

                            break;

                        case AltWizActionTypeEnum.SectionSizing:
                            canCommitAction = true;
                            break;
                    }


                    const nextButton: IActionButton = {
                        text: tm.Get("Next"),
                        color: ThemeColorEnum.Secondary,
                        disabled: !canCommitAction,
                        onClick: () => {
                            commitAction();
                            navBlockerActions.Unblock();
                        }
                    }


                    const cancelSequence: IActionButton = {
                        text: tm.Get("Cancel"),
                        color: ThemeColorEnum.Primary,
                        onClick: () => {
                            cancelWizard();
                            navBlockerActions.Unblock();
                        },
                        disabled: (odkey !== 0),
                    }

                    actionButtons.Set(0, nextButton);

                    if (!wizardState.cameFromMultiSelect) {
                        actionButtons.Set(1, cancelSequence);
                    }

                    break;

                case WizardModeEnum.MultiSelect:

                    const cancelMultiSelectButton: IActionButton = {
                        text: tm.Get("Cancel"),
                        color: ThemeColorEnum.Primary,
                        onClick: () => {
                            returnToMainWizard();
                            navBlockerActions.Unblock();
                        }
                    }

                    actionButtons.Set(0, cancelMultiSelectButton);
                    break;
            }
        }
    }, [wizardState.validations, wizardState.wizardView, wizardState.shapeEditorCanSave, wizardState.wizardMode, wizardState.altWizInfo,
    wizardState.webDesigner, wizardState.cameFromMultiSelect, wizardActions, addUpdateWizard, cancelWizard, commitAction, returnToMainWizard,
        submitShape, odkey, tm, simpleLineItem, navBlockerActions, actionButtons
    ]);

};
