import { useCallback } from "react";
import useBrowserBackedMap from "helpers/context/Parts/useBrowserBackedMap";
import { IPartDefaults } from "helpers/interfaces";
import PartsApi from "helpers/PartsAPI";



export default function usePartDefaults(partNo: string, partNoSuffix: string, shortcutName: string): PartDefaults {

    const sessionKey: string = "FV_PART_DEFAULT_CACHE";

    const partFetch = useCallback(async (partNo:string, partNoSuffix:string) => {
        return await PartsApi.GetPartDefaults(partNo, partNoSuffix, shortcutName);
    }, [shortcutName]);
    
    const partDefault: IPartDefaults|null = useBrowserBackedMap<IPartDefaults>(sessionKey, partFetch, partNo, partNoSuffix, shortcutName);

    return new PartDefaults(partDefault);
}


export class PartDefaults {

    constructor(defaults: IPartDefaults | null) {
        this.Defaults = defaults;
    }

    public Defaults: IPartDefaults | null;

    public CallSizeEnabled(): boolean {
        if (this.Defaults?.callSize == null || this.Defaults?.width == null || this.Defaults?.height == null)
            return false;
        else
            return true;
    }

    public QuantityEnabled(): boolean {
        return this.Defaults?.quantity != null;
    }

    public WidthEnabled(): boolean {
        return this.Defaults?.width != null;
    }

    public HeightEnabled(): boolean {
        return this.Defaults?.height != null;
    }

    public ThicknessEnabled(): boolean {
        return this.Defaults?.thickness != null;
    }

}