import { useState, useCallback } from "react";
import { FieldValues, UseFormRegister } from "react-hook-form";
import { Error } from "@mui/icons-material";
import { Button, InputAdornment, TextField, Tooltip } from "@mui/material";

import { ILineItemGridProps } from "components/Quotes/QuoteEntry/LineItems/LineItemGrid";
import { ThemeColorEnum } from "helpers/enums";
import { useTranslations } from "@fenetech/translations";
import { ILineItem, IQuotePermissions } from "helpers/interfaces";
import LineItemColumn, { IQuoteLevelProps } from "./LineItemColumn";
import Constants from "helpers/constants";

export default class LineItemQtyColumn extends LineItemColumn {

    register: UseFormRegister<FieldValues>;
    isReadOnly: boolean;

    public constructor(columnName: string, headerText: string, width: string, isReadOnly: boolean, register: UseFormRegister<FieldValues>) {
        super(columnName, headerText, width);
        this.align = "center";
        this.isReadOnly = isReadOnly;
        this.register = register;
    }

    public CellContent({ lineItemGridProps, permissions }: IQuoteLevelProps, lineItem: ILineItem): JSX.Element {

        return <QtyCell
            lineItemGridProps={lineItemGridProps}
            permissions={permissions}
            lineItem={lineItem}
            register={this.register}
            isReadOnly={this.isReadOnly}
        />;
    }

    public FooterContent({ lineItemGridProps }: IQuoteLevelProps): JSX.Element {

        const tm = lineItemGridProps.tm;

        return <>
            {!this.isReadOnly &&
                <Button
                    type="submit"
                    form="lineItemQtys"
                    variant="contained"
                    sx={{ minWidth: "100px", padding: "0" }}
                    color={ThemeColorEnum.Secondary}
                >{tm.Get("Update Qty")}
                </Button>
            }
        </>;

    }
}

interface IQtyCellProps {
    lineItemGridProps: ILineItemGridProps;
    permissions: IQuotePermissions;
    lineItem: ILineItem;
    register: UseFormRegister<FieldValues>;
    isReadOnly: boolean;
}

const QtyCell: React.FC<IQtyCellProps> = ({ lineItem, register, isReadOnly }: IQtyCellProps) => {

    const tm = useTranslations();
    const [validationError, setValidationError] = useState<string>("");
    const fieldName = `Quantity.${lineItem.lineItemNumber}`;

    const validateValue = useCallback((value: string): string | boolean => {

        if (value) {
            const floatValue = Number(value);
            const minValue = Constants.Min.Quantity;
            const maxValue = Constants.Max.Quantity;

            if (isNaN(floatValue)) {
                const message = tm.GetWithParams("Quantity must be between {0} and {1}.", minValue.toString(), maxValue.toString());
                return message;
            }
            else {
                if (floatValue > maxValue || floatValue < minValue) {
                    const message = tm.GetWithParams("Quantity must be between {0} and {1}.", minValue.toString(), maxValue.toString());
                    return message;
                }
            }
        }

        return true;
    }, [tm]);

    const onTextChanged = useCallback((e: any) => {
        let result = validateValue(e.target.value);
        if (typeof result === 'string') {
            setValidationError(result);
        }
        else {
            setValidationError("");
        }
    }, [validateValue]);

    const { onChange, ...restOfRegister } = register(fieldName, { validate: validateValue });

    const handleOnChange = useCallback((e: any) => {
        onChange(e);
        onTextChanged(e);
    }, [onChange, onTextChanged]);

    const ErrorAdornment = () => {
        return <>
            <Tooltip title={validationError}>
                <InputAdornment position="end">
                    <Error fontSize="small" color={ThemeColorEnum.Error} />
                </InputAdornment>
            </Tooltip>
        </>
    }

    return <>
        {isReadOnly ?
            lineItem.quantity
            :
            <TextField
                {...restOfRegister}
                variant="standard"
                sx={{ width: "50px" }}
                inputProps={{ style: { textAlign: "center" } }}
                fullWidth
                onChange={handleOnChange}
                error={validationError ? true : false}
                InputProps={{
                    endAdornment:
                        validationError ? <ErrorAdornment /> : null
                }}
            />
        }
    </>;

}
