import React, { useState, useCallback, useMemo } from 'react';
import { TableCell, TableRow, TextField } from "@mui/material";
import { useFormContext } from "react-hook-form";
import Constants from "helpers/constants";
import { useTranslations } from "@fenetech/translations";
import { ICurrencyFormatter } from "helpers/hooks/useCurrencyFormatter";
import ErrorAdornment from "components/Common/ErrorAdornment";

export interface ISurchargeDetail {
    index: number;
    odKey: number;
    formattedPart: string;
    partNo: string;
    partNoSuffix: string;
    unitPrice: number;
    cost: number | null;
    priceOverride: boolean;
    priceChanged: boolean;
}

interface IProps {
    initialState: ISurchargeDetail;
    isReadOnly: boolean;
    viewCost: boolean;
    cf: ICurrencyFormatter;
}

const SurchargeDetail: React.FC<IProps> = (props: IProps) => {

    return <>
        <TableRow>
            <TableCell>
                {props.initialState.formattedPart}
            </TableCell>
            {props.viewCost &&
                <TableCell align="right">
                    {props.cf.Format(props.initialState.cost ?? 0)}
                </TableCell>
            }
            <TableCell align="right">
                <SurchargePriceCell {...props} />
            </TableCell>
        </TableRow>
    </>;
};

const SurchargePriceCell: React.FC<IProps> = ({initialState, isReadOnly, viewCost, cf}: IProps) => {

    const tm = useTranslations();
    const { register } = useFormContext();
    const fieldName = `surcharge.${initialState.index}.unitPrice`;
    const [validationError, setValidationError] = useState<string>("");

    const validateValue = useCallback((value: string): string | boolean => {

        if (!value) {
            const message = tm.Get("Required");
            return message;
        }
        else {
            const floatValue = cf.Parse(value);

            const minValue = Constants.Min.Price;
            const maxValue = Constants.Max.Price;

            if (isNaN(floatValue)) {
                const message = tm.Get("Invalid value");
                return message;
            }
            else {
                if (floatValue > maxValue || floatValue < minValue) {
                    const message = tm.Get("Invalid value");
                    return message;
                }
            }
        }

        return true;
    }, [tm, cf]);

    const onTextChanged = useCallback((e: any) => {
        let result = validateValue(e.target.value);
        if (typeof result === 'string') {
            setValidationError(result);
        }
        else {
            setValidationError("");
        }
    }, [validateValue]);

    const isOverridden = useMemo(() => {
        return initialState.priceOverride;
    }, [initialState.priceOverride]);

    const { onChange, ...restOfRegister } = register(fieldName, { validate: validateValue });

    const handleOnChange = useCallback((e: any) => {
        onChange(e);
        onTextChanged(e);
    }, [onChange, onTextChanged]);

    return <>
        {isReadOnly ?
            <span style={{ fontWeight: isOverridden ? "bold" : undefined }}>
                {cf.Format(initialState.unitPrice)}
            </span>
            :
            <TextField
                variant="standard"
                inputProps={{ style: { textAlign: "right", fontWeight: isOverridden ? "bold" : undefined } }}
                {...restOfRegister}
                defaultValue={cf.Format(initialState.unitPrice)}
                error={validationError ? true : false}
                onChange={handleOnChange}
                InputProps={{
                    endAdornment:
                        validationError ? <ErrorAdornment validationError={validationError}/> : null
                }}
            />
        }
    </>;
};

export default SurchargeDetail;