import isNumeric from "fast-isnumeric";
import { Autocomplete, Box, FormControl, FormLabel, FormControlLabel, Grid, Stack, TextField, Typography, Radio, RadioGroup, DialogActions, DialogContent } from "@mui/material";
import useDimensionValue from "components/OptionsWizard/ItemProperties/useDimensionValue";
import Format, { ICallsizeArgs, ImperialFormatModeEnum } from "helpers/fv.format";
import { useTranslations } from "@fenetech/translations";
import { IPartCallSize, IPartCallSizePresets } from "helpers/interfaces";
import { IMeasurementType } from "models/IMeasurementType";
import { DisplacementDirectionEnum } from "helpers/enums";
import React, { useRef, useState, useEffect } from 'react';
import useIsMobile from "helpers/hooks/useIsMobile";
import useFormatHelper from "helpers/hooks/useFormatHelper";
import OKCancelControl from "components/Common/OKCancelControl";
import useLocaleNumberFormatter from "helpers/hooks/useLocaleNumberFormatter";
import useWizardState from "../WebDesigner/useWizardState";
import useMessageBox from "helpers/context/Page/useMessageBox";

interface IProps {
    measurementType: IMeasurementType,
    initialState: IODSLIProperties,
    callSizes: IPartCallSizePresets | null,
    openingWidth: number,
    openingHeight: number,
    widthFixed: boolean,
    heightFixed: boolean,
    onCancel: () => void,
    onSubmit: (originalState: IODSLIProperties, newState: IODSLIProperties) => void;
};


export interface IODSLIProperties {
    callSize: string | null,
    width: number;
    height: number;
    widthAdjustmentDirection: DisplacementDirectionEnum,
    heightAdjustmentDirection: DisplacementDirectionEnum,
    pinned: boolean,
}


const ODSLIProperties: React.FC<IProps> = ({ measurementType, initialState, callSizes, openingWidth, openingHeight, widthFixed, heightFixed, onCancel, onSubmit }: IProps) => {

    const tm = useTranslations();
    const lnf = useLocaleNumberFormatter({ style: "decimal", useGrouping: false, maximumFractionDigits: 4 });
    const messageBox = useMessageBox();
    const isMobile = useIsMobile();
    const wizardState = useWizardState();

    const partCallSizePresets = callSizes;

    const sizingInput = useRef<HTMLSelectElement>(null);

    const pinned = initialState.pinned;
    const initialWidth = initialState.width;
    const initialHeight = initialState.height;
    const initialWidthAdjustmentDirection = initialState.widthAdjustmentDirection;
    const initialHeightAdjustmentDirection = initialState.heightAdjustmentDirection;

    const formatMethods = useFormatHelper();

    const [callSize, setCallsize] = useState<string>(initialState.callSize ?? "");
    const [displayCallSize, setDisplayCallSize] = useState<string>("");

    const callSizeSelectChosen = React.useMemo(() => {
        return partCallSizePresets !== null && partCallSizePresets.callSizes.length > 0 && callSize !== "";
    }, [callSize, partCallSizePresets]);


    const callSizeLocked = React.useMemo<boolean>(() => {
        if (partCallSizePresets && partCallSizePresets.callSizes.length > 0 && partCallSizePresets.locked) {
            return true;
        } else {
            return false;
        }
    }, [partCallSizePresets]);


    const [width, widthString, handleWidthChange, handleWidthBlur, setWidth, setWidthString]
        = useDimensionValue(initialState.width, measurementType.setID, formatMethods, setCallsize);

    const [height, heightString, handleHeightChange, handleHeightBlur, setHeight, setHeightString]
        = useDimensionValue(initialState.height, measurementType.setID, formatMethods, setCallsize);

    const [widthPercent, setWidthPercent] = useState<number>(0);
    const [widthPercentString, setWidthPercentString] = useState<string>("");

    const [heightPercent, setHeightPercent] = useState<number>(0);
    const [heightPercentString, setHeightPercentString] = useState<string>("");

    const [widthAdjustmentDirection, setWidthAdjustmentDirection] = useState<DisplacementDirectionEnum>(initialWidthAdjustmentDirection);
    const [heightAdjustmentDirection, setHeightAdjustmentDirection] = useState<DisplacementDirectionEnum>(initialHeightAdjustmentDirection);

    const widthEnabled = !widthFixed && widthAdjustmentDirection !== DisplacementDirectionEnum.None && !pinned && !callSizeLocked;
    const heightEnabled = !heightFixed && heightAdjustmentDirection !== DisplacementDirectionEnum.None && !pinned && !callSizeLocked;
    const callsizeEnabled = widthEnabled || heightEnabled;

    useEffect(() => {
        setDisplayCallSize(callSize);
    }, [callSize]);

    useEffect(() => {
        if (sizingInput?.current) {
            sizingInput.current.focus();
        }
    }, [sizingInput]);

    useEffect(() => {
        let percentage = (width / openingWidth) * 100;
        percentage = Math.round(percentage * 10000) / 10000;
        setWidthPercent(percentage);
        setWidthPercentString(lnf.Format(percentage));
    }, [openingWidth, width, lnf]);

    useEffect(() => {
        let percentage = (height / openingHeight) * 100;
        percentage = Math.round(percentage * 10000) / 10000;
        setHeightPercent(percentage);
        setHeightPercentString(lnf.Format(percentage));
    }, [openingHeight, height, lnf]);

    useEffect(() => {
        let enableBothW = false;
        let enableLeft = false;
        let enableRight = false;
        let enableBothH = false;
        let enableTop = false;
        let enableBot = false;

        if (initialWidthAdjustmentDirection !== DisplacementDirectionEnum.None && initialWidthAdjustmentDirection !== DisplacementDirectionEnum.Opening && !pinned) {
            if (Math.abs(width - initialWidth) > .0001) {
                //Value changed
                switch (initialWidthAdjustmentDirection) {
                    case DisplacementDirectionEnum.Both:
                        enableBothW = true;
                        enableLeft = true;
                        enableRight = true;
                        break;
                    case DisplacementDirectionEnum.Left:
                        enableLeft = true;
                        break;
                    case DisplacementDirectionEnum.Right:
                        enableRight = true;
                        break;
                }
            }
        }

        if (initialHeightAdjustmentDirection !== DisplacementDirectionEnum.None && initialHeightAdjustmentDirection !== DisplacementDirectionEnum.Opening && !pinned) {
            if (Math.abs(height - initialHeight) > .0001) {
                //Value changed
                switch (initialHeightAdjustmentDirection) {
                    case DisplacementDirectionEnum.Both:
                        enableBothH = true;
                        enableTop = true;
                        enableBot = true;
                        break;
                    case DisplacementDirectionEnum.Top:
                        enableTop = true;
                        break;
                    case DisplacementDirectionEnum.Bottom:
                        enableBot = true;
                        break;
                }
            }
        }
        setBothWEnabled(enableBothW);
        setLeftEnabled(enableLeft);
        setRightEnabled(enableRight);
        setBothHEnabled(enableBothH);
        setTopEnabled(enableTop);
        setBotEnabled(enableBot);
    }, [width, height, initialWidth, initialHeight, initialWidthAdjustmentDirection, initialHeightAdjustmentDirection, pinned])

    const handleCallSizeTextChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setDisplayCallSize(e.target.value);
    };

    const validateWidthAndHeight = React.useCallback((newWidth: number, newHeight: number) => {
        if (!widthEnabled && newWidth !== width) {
            return tm.Get("Call size does not match fixed width. Please re-enter.");
        }
        if (!heightEnabled && newHeight !== height) {
            return tm.Get("Call size does not match fixed height. Please re-enter.");
        }
        return "";
    }, [width, height, widthEnabled, heightEnabled, tm]);

    const handleCallSizeTextBlur = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {

        let newCallSize: string = e.target.value;
        if (newCallSize === callSize) {
            return;
        }

        const currentWidth = width;
        const currentHeight = height;

        let newWidth = currentWidth;
        let newHeight = currentHeight;

        let callsizeArgs: ICallsizeArgs = {
            callSize: newCallSize,
            width: currentWidth,
            height: currentHeight,
        };

        if (Format.formatCallSize(callsizeArgs)) {
            //valid
            let validation = validateWidthAndHeight(callsizeArgs.width, callsizeArgs.height);
            if (validation === "") {
                newWidth = callsizeArgs.width;
                newHeight = callsizeArgs.height;
            } else {
                newCallSize = "";
                messageBox.Show({
                    message: validation,
                    title: tm.Get("Opening Designer")
                });
            }
        } else {
            //Invalid
            newCallSize = "";
        }

        const newFormattedWidth = formatMethods.formatDimensionText(newWidth, measurementType.setID, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false);
        const newFormattedHeight = formatMethods.formatDimensionText(newHeight, measurementType.setID, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false);

        setWidth(newWidth);
        setWidthString(newFormattedWidth);
        setHeight(newHeight);
        setHeightString(newFormattedHeight);

        setCallsize(newCallSize);
        setDisplayCallSize(newCallSize);
    };

    const handleCallSizeSelectChange = (event: any, data: IPartCallSize | null) => {

        let newCallSize: string = data?.callSize ?? "";

        const currentWidth = width;
        const currentHeight = height;

        let newWidth = currentWidth;
        let newHeight = currentHeight;

        if (newCallSize) {
            if (callSizes) {
                const selectedCallSize = callSizes.callSizes.filter(cs => cs.callSize === newCallSize);
                if (selectedCallSize.length > 0) {
                    let validation = validateWidthAndHeight(selectedCallSize[0].width, selectedCallSize[0].height);
                    if (validation === "") {
                        newWidth = selectedCallSize[0].width;
                        newHeight = selectedCallSize[0].height;
                    } else {
                        newCallSize = "";
                        messageBox.Show({
                            message: validation,
                            title: tm.Get("Opening Designer")
                        });
                    }
                }
            }
        }

        const newFormattedWidth = formatMethods.formatDimensionText(newWidth, measurementType.setID, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false);
        const newFormattedHeight = formatMethods.formatDimensionText(newHeight, measurementType.setID, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false);

        setWidth(newWidth);
        setWidthString(newFormattedWidth);
        setHeight(newHeight);
        setHeightString(newFormattedHeight);
        setCallsize(newCallSize);

    };


    const selectedCallSize = React.useCallback(() => {
        if (partCallSizePresets && partCallSizePresets.callSizes && partCallSizePresets.callSizes.length > 0) {
            if (callSize) {
                const item = partCallSizePresets.callSizes.find(cs => cs.callSize === callSize);
                return item ?? null;
            } else {
                return null;
            }

        }
        return null;
    }, [callSize, partCallSizePresets]);

    const handleWidthPercentChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setWidthPercentString(e.target.value);
    };

    const handleWidthPercentBlur = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let newWidth = 0;
        let d = lnf.Parse(e.target.value);
        if (d === widthPercent) return;

        if (!isNaN(d)) {
            if (Math.abs(d - widthPercent) < Format.DECIMAL_TOLERANCE) {
                return;
            } else {
                newWidth = Math.round((d / 100) * openingWidth * 10000) / 10000
                setWidthPercent(d);
            }
        } else {
            setWidthPercentString("0");
            setWidthPercent(0);
        }

        let formatted = formatMethods.formatDimensionText(newWidth, measurementType.setID, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false);
        setWidth(newWidth);
        setWidthString(formatted);
        setCallsize("");
    };

    const handleHeightPercentChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setHeightPercentString(e.target.value);
    };

    const handleHeightPercentBlur = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let newHeight = 0;
        let d = lnf.Parse(e.target.value);
        if (d === heightPercent) return;

        if (!isNaN(d)) {
            if (Math.abs(d - heightPercent) < Format.DECIMAL_TOLERANCE) {
                return;
            } else {
                newHeight = Math.round((d / 100) * openingHeight * 10000) / 10000
                setHeightPercent(d);
            }
        } else {
            setHeightPercentString("0");
            setHeightPercent(0);
        }

        let formatted = formatMethods.formatDimensionText(newHeight, measurementType.setID, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false);
        setHeight(newHeight);
        setHeightString(formatted);
        setCallsize("");
    };

    const handleWidthAdjustmentDirectionChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (isNumeric(e.target.value)) {
            setWidthAdjustmentDirection(parseInt(e.target.value));
        }
    };

    const handleHeightAdjustmentDirectionChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (isNumeric(e.target.value)) {
            setHeightAdjustmentDirection(parseInt(e.target.value));
        }
    };

    const handleSubmit = React.useCallback(() => {

        const newState: IODSLIProperties = {
            width,
            height,
            callSize,
            widthAdjustmentDirection,
            heightAdjustmentDirection,
            pinned,
        };

        onSubmit(initialState, newState);

    }, [initialState, onSubmit, callSize, width, height, widthAdjustmentDirection, heightAdjustmentDirection, pinned]);


    let measurementLabel: string;

    if (measurementType.setID === 1)
        measurementLabel = tm.Get("inches");
    else
        measurementLabel = tm.Get("mm");

    const [bothWEnabled, setBothWEnabled] = useState<boolean>(false);
    const [leftEnabled, setLeftEnabled] = useState<boolean>(false);
    const [rightEnabled, setRightEnabled] = useState<boolean>(false);
    const [bothHEnabled, setBothHEnabled] = useState<boolean>(false);
    const [topEnabled, setTopEnabled] = useState<boolean>(false);
    const [botEnabled, setBotEnabled] = useState<boolean>(false);


    return <>
        <DialogContent>
            <Box display="flex" flexDirection="column" mt={1}>
                <Grid container direction="column" rowSpacing={1} justifyItems="stretch">
                    <Grid item>
                        <FormControl id="callsize" fullWidth>
                            {partCallSizePresets && partCallSizePresets.callSizes.length > 0 ?
                                <Autocomplete
                                    autoComplete
                                    disableClearable={callSizeLocked}
                                    disabled={!callsizeEnabled}
                                    autoSelect
                                    autoHighlight
                                    selectOnFocus
                                    handleHomeEndKeys
                                    options={partCallSizePresets.callSizes}
                                    getOptionLabel={(cs: IPartCallSize) => cs.callSize}
                                    isOptionEqualToValue={(cs: IPartCallSize, value: IPartCallSize) => cs.callSize === value.callSize}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={tm.Get("Call Size")}
                                            InputProps={{
                                                ...params.InputProps,
                                            }}
                                            inputProps={{
                                                ...params.inputProps,
                                                inputMode: wizardState.userPreferences.enableMobileKeyboard ? "text" : "none"
                                            }}
                                        />
                                    )}
                                    onChange={handleCallSizeSelectChange}
                                    value={selectedCallSize()}
                                />
                                :
                                <TextField label={tm.Get("Call Size")} value={displayCallSize} onChange={handleCallSizeTextChange} onBlur={handleCallSizeTextBlur} disabled={!callsizeEnabled} fullWidth />
                            }
                        </FormControl>

                    </Grid>

                    <Grid item>
                        <Stack direction={isMobile ? "column" : "row"} alignItems="center" justifyContent="left" spacing={1}  >
                            <TextField label={tm.Get("Width")} value={widthString} onChange={handleWidthChange} onBlur={handleWidthBlur} fullWidth
                                disabled={!widthEnabled || callSizeSelectChosen}
                                onFocus={e => e.target.select()}
                                InputProps={{ endAdornment: <Typography>{measurementLabel}</Typography> }}
                            />
                            <TextField label={tm.Get("Width") + " %"} value={widthPercentString} onChange={handleWidthPercentChange} onBlur={handleWidthPercentBlur} fullWidth
                                disabled={!widthEnabled || callSizeSelectChosen}
                                onFocus={e => e.target.select()}
                                InputProps={{ endAdornment: <Typography>%</Typography> }}
                            />

                        </Stack>
                    </Grid>

                    <Grid item>
                        <Stack direction={isMobile ? "column" : "row"} alignItems="center" justifyContent="left" spacing={1}  >
                            <TextField label={tm.Get("Height")} value={heightString} onChange={handleHeightChange} onBlur={handleHeightBlur} fullWidth
                                disabled={!heightEnabled || callSizeSelectChosen}
                                onFocus={e => e.target.select()}
                                InputProps={{ endAdornment: <Typography>{measurementLabel}</Typography> }}
                            />
                            <TextField label={tm.Get("Height") + " %"} value={heightPercentString} onChange={handleHeightPercentChange} onBlur={handleHeightPercentBlur} fullWidth
                                disabled={!heightEnabled || callSizeSelectChosen}
                                onFocus={e => e.target.select()}
                                InputProps={{ endAdornment: <Typography>%</Typography> }}
                            />
                        </Stack>
                    </Grid>

                    <Grid item>
                        <Stack direction={"row"} alignItems="center" justifyContent="left" spacing={1} >
                            <FormControl id="widthAdjustmentDirection" fullWidth>
                                <FormLabel>{tm.Get("Width Adjustment Direction")}</FormLabel>
                                <RadioGroup row value={widthAdjustmentDirection} onChange={handleWidthAdjustmentDirectionChange}>
                                    <FormControlLabel disabled={!bothWEnabled} value={DisplacementDirectionEnum.Both} control={<Radio />} label={tm.Get("Both")} />
                                    <FormControlLabel disabled={!leftEnabled} value={DisplacementDirectionEnum.Left} control={<Radio />} label={tm.Get("Left")} />
                                    <FormControlLabel disabled={!rightEnabled} value={DisplacementDirectionEnum.Right} control={<Radio />} label={tm.Get("Right")} />
                                </RadioGroup>
                            </FormControl>
                        </Stack>
                    </Grid>

                    <Grid item>
                        <Stack direction={"row"} alignItems="center" justifyContent="left" spacing={1} >
                            <FormControl id="heightAdjustmentDirection" fullWidth>
                                <FormLabel>{tm.Get("Height Adjustment Direction")}</FormLabel>
                                <RadioGroup row value={heightAdjustmentDirection} onChange={handleHeightAdjustmentDirectionChange} >
                                    <FormControlLabel disabled={!bothHEnabled} value={DisplacementDirectionEnum.Both} control={<Radio />} label={tm.Get("Both")} />
                                    <FormControlLabel disabled={!topEnabled} value={DisplacementDirectionEnum.Top} control={<Radio />} label={tm.Get("Top")} />
                                    <FormControlLabel disabled={!botEnabled} value={DisplacementDirectionEnum.Bottom} control={<Radio />} label={tm.Get("Bottom")} />
                                </RadioGroup>
                            </FormControl>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </DialogContent>
        <DialogActions>
            <OKCancelControl okCommand={handleSubmit} cancelCommand={onCancel} okText={tm.Get("Update")} />
        </DialogActions>
    </>;
}

export default ODSLIProperties;