
import { Box, Button, Drawer, Grid, Typography, Stack, AppBar, IconButton } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { useMemo } from "react";
import NavMenuMobile from "./NavMenuMobile";
import { Menu as MenuIcon } from "@mui/icons-material"
import usePageData from "helpers/context/Page/usePageData";
import useIsMobile from "helpers/hooks/useIsMobile";

import { useNavigate } from "react-router";
import NavMenu from "./NavMenu";
import { IActionBackButton, IActionButton, IActionCreateQuoteButton } from "helpers/context/Page/PageContext";
import { useTranslations } from "@fenetech/translations";
import { RoleEnum, ThemeColorEnum } from "helpers/enums";
import useUserInfo from "helpers/context/User/useUserInfo";
import useConfiguratorInfo from "helpers/context/Configurator/useConfiguratorInfo";


interface IProps {
}



const ActionBar: React.FC<React.PropsWithChildren<IProps>> = (props: React.PropsWithChildren<IProps>) => {

    const tm = useTranslations();
    const pageInfo = usePageData();
    const navigate = useNavigate();
    const userInfo = useUserInfo();
    const configuratorInfo = useConfiguratorInfo();

    const [menuOpen, setMenuOpen] = React.useState<boolean>(false);

    const handleMenuToggle = (open: boolean) => {
        setMenuOpen(open);
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleNavClick = (uri: string) => {
        setMenuOpen(false);
        navigate(uri);
    };

    const isMobile = useIsMobile();

    const hamburgerVisible = useMemo(() => {
        return !configuratorInfo.embedded && (userInfo.HasRole(RoleEnum.Dealer) || userInfo.HasRole(RoleEnum.Contractor) || userInfo.HasRole(RoleEnum.CSR)) && !userInfo.isAccessedExternal;
    }, [userInfo, configuratorInfo]);

    return <AppBar position="sticky">
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
            {isMobile && hamburgerVisible &&
                <Drawer
                    anchor="left"
                    open={menuOpen}
                    onClose={() => handleMenuToggle(false)}
                >
                    {/* If we are desktop, we have to reserve space at the top of the drawer so the header doesn't overlap the drawer contents */}
                    {!isMobile && <>
                        <Box sx={{ height: 0 }} />
                    </>}
                    <NavMenuMobile closeMenu={() => handleMenuToggle(false)} />
                </Drawer>
            }

            {!isMobile && hamburgerVisible && <>
                <NavMenu anchorElement={anchorEl} menuOpen={menuOpen} setMenuOpen={handleMenuToggle} handleNavClick={handleNavClick} />

            </>}

            <Grid item xs >
                <Box display="flex" gap={1} margin={0.5} alignItems="center">
                    {hamburgerVisible &&
                        <Grid item xs="auto">
                            <Button variant="contained" onClick={(e) => { handleMenuToggle(!menuOpen); handleClick(e); }} size="small" color={ThemeColorEnum.Secondary}>
                                <MenuIcon />
                            </Button>
                        </Grid>
                    }

                    <Typography variant="h6" >
                        {pageInfo.title}
                    </Typography>
                </Box>
            </Grid>
            
            <Grid item xs="auto" >
                <Stack direction="row">
                    {pageInfo.actionContent}
                    <Box display="flex" gap={1} margin={0.5}>
                        {pageInfo.actionButtons.map((c, index) => {
                            if (c.hasOwnProperty("text")) {
                                //IActionButton
                                return <Button variant="contained" size="small"
                                    key={(c as IActionButton).text}
                                    {...c}
                                >
                                    {(c as IActionButton).text}
                                </Button>
                            } else if (c.hasOwnProperty("untranslatedText")) {
                                //IActionCreateQuoteButton
                                return <Button variant="contained" size="small"
                                    key={(c as IActionCreateQuoteButton).untranslatedText}
                                    color={ThemeColorEnum.Secondary}
                                    onClick={() => { navigate((c as IActionCreateQuoteButton).navigateURL) }}
                                >
                                    {tm.Get((c as IActionCreateQuoteButton).untranslatedText)}
                                </Button>
                            } else {
                                //IActionBackButton
                                return <IconButton key={index} size="small" onClick={() => { navigate((c as IActionBackButton).navigateURL) }} sx={{ paddingY: 0 }}>
                                    <ArrowBackIcon color="secondary" />
                                </IconButton>
                            }
                        })}
                    </Box>
                </Stack>
            </Grid>
        </Grid>
    </AppBar>;

};


export default ActionBar;

