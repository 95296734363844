import * as React from "react";
import { ISubLineItemInfo } from "../interfaces";
import Format from "helpers/fv.format";
import { Checkbox, Divider, FormControlLabel, Grid } from "@mui/material";


interface IProps {
    sectionList: Array<ISubLineItemInfo>;
    itemClickedHandler: (itemClicked: ISubLineItemInfo) => void;
}


export default class MultiSelectCheckboxes extends React.Component<IProps> {

    private sliWhereTypeChangeHappens: number;

    constructor(props: IProps) {
        super(props);
        this.state = { partButtonEnabled: false, optionsButtonEnabled: false };
        this.sliWhereTypeChangeHappens = this.getSubLineWhereSplitOccurs();
    }

    render(): React.ReactNode {
        return <>
            {this.props.sectionList && this.props.sectionList.length > 0 &&
                <Grid container direction="column" alignItems="stretch" border="solid 1px" pl={1}>
                    {this.props.sectionList.map((s: ISubLineItemInfo) => {
                        if (s.sli !== 0) {
                            return <Grid key={s.sli} item >
                                <MultiSelectSingleCheckbox
                                    sli={s.sli}
                                    isTypeChangeSLI={s.sli === this.sliWhereTypeChangeHappens}
                                    checkboxOnClick={this.checkboxOnClick}
                                    partDescription={s.partDescription}
                                    selected={s.isSelected}
                                />
                            </Grid>
                        } else {
                            return null;
                        }
                    })}
                </Grid>
            }
        </>
    }

    getSubLineWhereSplitOccurs(): number {
        let previousType: number = -1;

        for (var i = 0; i < this.props.sectionList.length; i++) {
            if (previousType !== -1 && this.props.sectionList[i].subLineItemType !== previousType)
                return this.props.sectionList[i].sli;

            previousType = this.props.sectionList[i].subLineItemType;
        }

        return 0;
    }

    checkboxOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {

        let inputEle: HTMLButtonElement = e.currentTarget as HTMLButtonElement;
        let parent = inputEle.parentElement;
        let sliString = parent?.getAttribute("sli");

        if (sliString) {
            let sliNumber = parseInt(sliString);
            const sli: ISubLineItemInfo | undefined = this.props.sectionList.find(f => f.sli === sliNumber)
            if (sli) {
                this.props.itemClickedHandler(sli);
            }
        }

    }

}


interface ISingleCheckboxProps {
    selected: boolean;
    sli: number;
    partDescription: string;
    checkboxOnClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
    isTypeChangeSLI: boolean;
}


const MultiSelectSingleCheckbox: React.FC<ISingleCheckboxProps> = (props: ISingleCheckboxProps) => {


    let key: string = "chk_" + Format.FormatSubLineItem(props.sli);

    const customAttribute = { sli: props.sli };

    const checkbox = <FormControlLabel
        key={key}
        {...customAttribute}
        control={<Checkbox
            onClick={props.checkboxOnClick}
            checked={props.selected}
            inputProps={{
                alt: Format.FormatSubLineItem(props.sli)
            }}

        />}
        label={Format.FormatSubLineItem(props.sli) + (props.partDescription ? " - " + props.partDescription:"")}
    />

    if (props.isTypeChangeSLI) {
        return <>
            <Divider />
            {checkbox}
        </>

    } else {
        return checkbox;
    }
}

