import useMfgCustomersAndSites from "helpers/context/SelectionValues/useMfgCustomersAndSites";
import useUserInfo from "helpers/context/User/useUserInfo";
import { RoleEnum, UserPermissionTypes } from "helpers/enums";
import React from "react";

export interface IMenuItemRecord {
    label: string;
    uri: string;
    items?: IMenuItemRecord[];
    requiredRoles?: RoleEnum[];
    requiredPermission?:UserPermissionTypes;
    requiredGSPs?:string[];
    requiresAdmin?:boolean;
}

export default function useMenuItems (): IMenuItemRecord[] {

    const user = useUserInfo();
    const mfgSites = useMfgCustomersAndSites();
    
    const companyURI = React.useMemo(() => {
        if (!mfgSites || user.isMultiSite) {
            return "/setup/company"
        }
        // If single site, cannot assume siteID == MfgCustomerID so get from siteID list
        const firstSiteID = mfgSites.GetMfgSitesFromMfgCustomerID(user.mfgCustomerID)[0].siteID
        return `/setup/company/view/${firstSiteID}`;
    }, [mfgSites, user])

    return [
        {
            label: "Dashboard",
            uri: "/dashboard",
            requiredRoles: [RoleEnum.Dealer, RoleEnum.Contractor]
        },
        {
            label: "Search",
            uri: "/search",
            requiredRoles: [RoleEnum.Dealer, RoleEnum.Contractor, RoleEnum.CSR]
        },
        {
            label: "Quote Maintenance",
            uri: "/maintenance",
            requiredRoles: [RoleEnum.Dealer, RoleEnum.Contractor, RoleEnum.CSR]
        },
        {
            label: "Documents",
            uri: "/documents",
            requiredGSPs: ["supportFiles"],
            requiredRoles: [RoleEnum.Dealer]
        },
        {
            label: "Orders",
            requiredRoles: [RoleEnum.Dealer],
            uri: "",
            items: [
                { label: "Order History", uri: "/orderhistory" },
                { 
                    label: "Purchase History", 
                    uri: "/purchasehistory", 
                    requiredPermission: UserPermissionTypes.ViewCost,
                }
            ]
        },
        {
            label: "Setup",
            uri: "",
            requiredRoles: [RoleEnum.Dealer, RoleEnum.Contractor],
            requiredPermission: UserPermissionTypes.CreateModifyCustomersAndPricing,
            items: [
                { label: "Customers", uri: "/setup/customers" },
                { label: "Terms", uri: "/setup/terms" },
                { label: "Ship Via", uri: "/setup/shipvia" },
                { 
                    label: "Pricing", 
                    uri: "",
                    items: [
                        { label: "Tables", uri: "/setup/pricing/tables" },
                        { label: "Schedules", uri: "/setup/pricing/schedules", }
                    ]
                },
                {
                    label: "Company",
                    uri: companyURI,
                    requiresAdmin:true
                },
                {
                    label: "Users",
                    uri: "/setup/users",
                    requiresAdmin:true
                },
            ]
        }
    ];
}