import * as React from "react";

import { ViewTypeEnum } from "../enum";
import ImageWithLoadingOverlay from "../GenericComponents/ImageWithLoadingOverlay";
import { IWebDesigner, IAltWizInfo, ISectionRegion, ISubLineItemInfo } from "../interfaces";
import WizardHelper from "../WizardHelper";
import { ITranslationManager } from "@fenetech/translations";
import { Grid } from "@mui/material";
import { WizardModeEnum } from "components/OptionsWizard/WizardContext";
import APIHelper from "helpers/APIHelper";
import useWizardState from "../WebDesigner/useWizardState";
import ClickableImageWithLoadingOverlay from "../GenericComponents/ClickableImageWithLoadingOverlay";

interface IOVProps {
    showMain: boolean;
    showCross: boolean;
    showTop: boolean;
    optionHash: string;
    webDesigner: IWebDesigner | null;
    altWizInfo: IAltWizInfo | null;
    clickRegionHandler?: (clickableReg: ISectionRegion, tm: ITranslationManager) => void;
    mode: WizardModeEnum;
    tm: ITranslationManager;
    renderAsDiv: boolean;
    showImage: boolean;
    setHasObjectViewerImage: React.Dispatch<React.SetStateAction<boolean>>;
}

const ObjectViewer: React.FC<IOVProps> = (props: IOVProps) => {

    const wizardState = useWizardState();
    const currentView = wizardState.objectView!;

    const [hasTop, setHasTop] = React.useState<boolean>(false);
    const [hasMain, setHasMain] = React.useState<boolean>(false);
    const [hasCross, setHasCross] = React.useState<boolean>(false);

    React.useEffect(() => {
        props.setHasObjectViewerImage(hasTop || hasMain || hasCross);
    }, [hasTop, hasMain, hasCross, props]);

    function getClickableRegions(): Array<ISectionRegion> | null {
        if (props.webDesigner)
            return WizardHelper.GetClickableRegions(props.webDesigner.regions, currentView);
        else
            return null;
    }

    const getClickableRegionHandler: (tm: ITranslationManager) => ((clickableReg: ISectionRegion) => void) | undefined = (tm) => {
        if (props.webDesigner && props.mode !== WizardModeEnum.SequencedWizard && props.clickRegionHandler) {
            return (cr: ISectionRegion) => {
                props.clickRegionHandler && props.clickRegionHandler(cr, tm);
            }
        } else {
            return undefined;
        }
    }

    const getSelectedItems: () => (number[] | null) = () => {
        if (props.webDesigner) {
            if (props.altWizInfo) {
                return props.altWizInfo.selectedItems;
            } else {
                if (props.mode === WizardModeEnum.MultiSelect) {
                    const selected: ISubLineItemInfo[] = props.webDesigner.sectionList.filter((sli: ISubLineItemInfo) => sli.isSelected);
                    return selected.map((sli: ISubLineItemInfo) => sli.sli);
                } else {
                    if (props.webDesigner.currentSection) {
                        return [props.webDesigner.currentSection];
                    } else {
                        return null;
                    }
                }
            }
        } else {
            return null;
        }
    }

    function getMainImageUrl(props: IOVProps, currentView: ViewTypeEnum): string {
        let useDesignerImage: boolean = false;

        if (!props.altWizInfo && props.webDesigner) {
            //Not in alternate wizard, you can always use the designer image if a designer exists
            useDesignerImage = true;
        } else if (props.altWizInfo) {
            //In alternate wizard, the current section doesn't have an image of its own, and we aren't doing the small overall image
            useDesignerImage = true;
        }

        if (useDesignerImage) {
            return APIHelper.PrependBaseURL(`api/Images/DesignerImage?stateHash=${props.optionHash}&objectView=${currentView}`);
        } else
            return APIHelper.PrependBaseURL(`api/Images/OV?optionHash=${props.optionHash}&objectView=${currentView}&section=0`);
    }

    function getTopImageUrl(currentView: ViewTypeEnum): string {
        return APIHelper.PrependBaseURL(`api/Images/OV?optionHash=${props.optionHash}&objectView=${currentView}&section=2`);
    }

    function getSideImageUrl(currentView: ViewTypeEnum): string {
        return APIHelper.PrependBaseURL(`api/Images/OV?optionHash=${props.optionHash}&objectView=${currentView}&section=1`);
    }

    if (!props.showImage) {
        return null;
    }

    if (!props.showTop && !props.showCross) {

        return <ClickableImageWithLoadingOverlay src={getMainImageUrl(props, currentView)}
            clickableRegions={getClickableRegions()}
            clickRegionHandler={getClickableRegionHandler(props.tm)}
            selected={getSelectedItems()}
            renderAsDiv={props.renderAsDiv}
            imageStyle={{ display: "flex", width: "100%", height: "100%", maxWidth: "100%", maxHeight: "100%", objectFit: "contain", overflowY: "hidden" }}
            setHasImage={setHasMain}
        />
    }
    else {
        return <Grid display="flex" container direction="column">

            {props.showTop && props.optionHash &&
                <Grid item xs="auto" alignSelf={"center"} >
                    <ImageWithLoadingOverlay src={getTopImageUrl(currentView)} renderAsDiv={props.renderAsDiv} setHasImage={setHasTop} />
                </Grid>
            }

            <Grid item container direction="row">
                {props.showMain && props.optionHash &&
                    <Grid item xs>
                        <ClickableImageWithLoadingOverlay src={getMainImageUrl(props, currentView)}
                            clickableRegions={getClickableRegions()}
                            clickRegionHandler={getClickableRegionHandler(props.tm)}
                            selected={getSelectedItems()}
                            renderAsDiv={props.renderAsDiv}
                            imageStyle={{ width: "100%" }}
                            setHasImage={setHasMain}
                        />
                    </Grid>
                }

                {props.showCross && props.optionHash &&
                    <Grid item xs="auto" alignSelf={"center"}>
                        <ImageWithLoadingOverlay src={getSideImageUrl(currentView)} renderAsDiv={props.renderAsDiv} setHasImage={setHasCross} />
                    </Grid>
                }
                
            </Grid>

        </Grid>;
    }
}

export default ObjectViewer;