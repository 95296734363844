import { ConfiguratorAction, ConfiguratorActionEnum } from "helpers/context/Configurator/ConfiguratorContext";
import { CaseInsensitiveCompare } from "helpers/objects";

export default class ConfiguratorHandler {

    #dispatch: React.Dispatch<ConfiguratorAction>;

    constructor(dispatch: React.Dispatch<ConfiguratorAction>) {
        this.#dispatch = dispatch;
    }

    private SetEmbedded = () => {
        this.#dispatch({ action: ConfiguratorActionEnum.SetEmbedded, value: true });
    }

    private SetConfigurator = (configurator: string) => {
        this.#dispatch({ action: ConfiguratorActionEnum.SetConfigurator, value: configurator });
    }

    public SetInstanceIsEmbedded() {
        this.SetEmbedded();
    }

    public SetConfiguratorMode(configurator: string) {
        this.SetConfigurator(configurator);
    }

    public PostLoginSuccess(configurator: string) {
        if (window.parent !== null) {
            if (CaseInsensitiveCompare(configurator, "ShellTest")) {
                window.parent.postMessage({
                    type: "login-success",
                    message: "success"
                }, "*");
            } else if (CaseInsensitiveCompare(configurator, "OCC")) {
                window.parent.postMessage({
                    type: "login-success",
                    message: "success"
                }, "*");
            }
        }
    }

    public PostProductNavigatorCancel(configurator: string) {
        if (window.parent !== null) {
            if (CaseInsensitiveCompare(configurator, "ShellTest")) {
                window.parent.postMessage({
                    type: "prodnav-cancel",
                    message: "cancel"
                }, "*");
            } else if (CaseInsensitiveCompare(configurator, "OCC")) {
                window.parent.postMessage({
                    type: "prodnav-cancel",
                    message: "cancel"
                }, "*");
            }
        }
    }

    public PostOptionsWizardCancel(configurator: string) {
        if (window.parent !== null) {
            if (CaseInsensitiveCompare(configurator, "ShellTest")) {
                window.parent.postMessage({
                    type: "wizard-cancel",
                    message: "cancel"
                }, "*");
            } else if (CaseInsensitiveCompare(configurator, "OCC")) {
                window.parent.postMessage({
                    type: "wizard-cancel",
                    message: "cancel"
                }, "*");
            } 
        }
    }

    public PostOptionsWizardSave(configurator: string, odKey: number) {
        if (window.parent !== null) {
            if (CaseInsensitiveCompare(configurator, "ShellTest")) {
                window.parent.postMessage({
                    type: "wizard-save",
                    message: odKey
                }, "*");
            } else if (CaseInsensitiveCompare(configurator, "OCC")) {
                window.parent.postMessage({
                    type: "wizard-save",
                    message: odKey
                }, "*");
            }
        }
    }

};


