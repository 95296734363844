import { useCallback, useEffect, useMemo, useState } from "react";
import { Container, Box, Grid, Stack, Collapse } from "@mui/material";

import QuoteHeaderActions from "components/Quotes/QuoteEntry/Header/QuoteHeaderActions";
import MfgCustomerCard from "components/Quotes/QuoteEntry/Header/MfgCustomerCard";
import WebCustomerCard from "components/Quotes/QuoteEntry/Header/WebCustomerCard";
import BillingCard from "components/Quotes/QuoteEntry/Header/BillingCard";
import ShippingCard from "components/Quotes/QuoteEntry/Header/ShippingCard";
import SalesCard from "components/Quotes/QuoteEntry/Header/SalesCard";
import QuoteInfoCard from "components/Quotes/QuoteEntry/Header/QuoteInfoCard";
import FooterCard from "components/Quotes/QuoteEntry/Footer/FooterCard";
import MoreLessButton from "components/Common/MoreLessButton";
import StatusChip from "components/Quotes/QuoteEntry/Header/StatusChip";
import SubmittedChip from "components/Quotes/QuoteEntry/Header/SubmittedChip";
import ExpirationCard from "components/Quotes/QuoteEntry/Header/ExpirationCard";
import AddLineItem from "components/Quotes/QuoteEntry/AddLineItem/AddLineItem";
import LineItemGrid from "components/Quotes/QuoteEntry/LineItems/LineItemGrid";
import SurchargeDialog from "components/Quotes/QuoteEntry/Surcharges/SurchargeDialog";
import TaxesDialog from "components/Quotes/QuoteEntry/Taxes/TaxesDialog";

import { useTranslations } from "@fenetech/translations";
import useWindowTitle from "helpers/context/Title/useWindowTitle";
import useUserInfo from "helpers/context/User/useUserInfo";
import useQuoteActions from "components/Quotes/useQuoteActions";
import useQuoteData from "components/Quotes/useQuoteData";
import useApplicationInfo from "helpers/context/Application/useApplicationInfo";
import useWait from "helpers/context/Page/useWait";

import { RoleEnum, ThemeColorEnum } from "helpers/enums";
import { ISurchargeOverrideInfo, ITaxOverrideInfo } from "helpers/interfaces";
import { ISurchargeInfo } from "components/Quotes/QuoteEntry/Surcharges/Surcharges";
import { ITaxesInfo } from "components/Quotes/QuoteEntry/Taxes/Taxes";
import LoadingLinearProgress from "components/Common/LoadingLinearProgress";
import { useNavigate } from "react-router-dom";
import useActionButtons from "helpers/context/Page/useActionButtons";
import { IActionButton } from "helpers/context/Page/PageContext";
import { useEffectOnLoad } from "helpers/hooks/useEffectOnLoad";
import RelatedLinksDialog from "./Header/RelatedLinksDialog";
import OrdersAPI from "components/Orders/OrdersAPI";
import QuoteLockChip from "./Header/QuoteLockChip";

const QuoteEntry: React.FC<any> = () => {

    const tm = useTranslations();
    const [viewMore, setViewMore] = useState<boolean>(false);
    const [surchargeDialogVisible, setSurchargeDialogVisible] = useState<boolean>(false);
    const [taxesDialogVisible, setTaxesDialogVisible] = useState<boolean>(false);
    const [relatedLinksDialogVisible, setRelatedLinksDialogVisible] = useState<boolean>(false);
    const [mfgOrderNumber, setMfgOrderNumber] = useState<string | undefined>(undefined);
    const user = useUserInfo();
    const appInfo = useApplicationInfo();
    const quoteActions = useQuoteActions();
    const actionButtons = useActionButtons();
    const navigate = useNavigate();
    const { quote, lineItems, totals, permissions, lock, taxDetails } = useQuoteData();
    const wait = useWait();

    const contractor = user.HasRole(RoleEnum.Contractor);

    const title = useMemo(() => {
        if (quote) {
            return `${tm.Get("Quote")} - #${quote.orderNumber ?? ""}`;
        }
        return "";
    }, [quote, tm]);

    useWindowTitle(title);

    useEffectOnLoad(() => {
        const finishButton: IActionButton = {
            text: tm.Get("Finish"),
            color: ThemeColorEnum.Secondary,
            onClick: handleFinishClick
        };

        actionButtons.Set(0, finishButton);
    });

    useEffect(() => {
        if (quote?.mfgOKey !== undefined && quote.mfgOKey > 0) {
            OrdersAPI.GetOrderNumber(quote.mfgOKey).then((orderNumber) => {
                setMfgOrderNumber(orderNumber);
            });
        }
    }, [quote])

    const onAcquireLock = useCallback(async () => {
        if (quote?.oKey) {
            wait.Show(true);
            await quoteActions.LoadQuoteAsync(quote.oKey, true);
            wait.Show(false);
        }
    }, [wait, quote, quoteActions]);

    const priceVisible = useMemo(() => {
        return permissions?.viewPrice ?? false;
    }, [permissions]);

    const sqFtPriceVisible = useMemo(() => {
        return priceVisible && appInfo.parameters.showSquareFeet;
    }, [priceVisible, appInfo]);

    const costVisible = useMemo(() => {
        return permissions?.viewCost ?? false;
    }, [permissions]);

    const userCanModifyQuote = useMemo(() => {
        if (permissions && lock) {
            return permissions.editQuote && !lock.isLockedByAnotherUser;
        }
        return false;
    }, [permissions, lock]);

    const userCanModifyPricing = useMemo(() => {
        if (permissions && lock) {
            return permissions.editPrice && !lock.isLockedByAnotherUser;
        }
        return false;
    }, [permissions, lock]);

    const userCanModifyTaxes = useMemo(() => {
        if (permissions && lock) {
            return permissions.editTax && !lock.isLockedByAnotherUser;
        }
        return false;
    }, [permissions, lock]);

    const overrideSurcharges = useCallback(async (originalState: ISurchargeInfo, newState: ISurchargeInfo) => {
        setSurchargeDialogVisible(false);

        if (quote?.oKey) {
            wait.Show(true);

            const surchargeOverrideInfos: ISurchargeOverrideInfo[] = [];

            newState.details.forEach((s) => {
                surchargeOverrideInfos.push({ partNo: s.partNo, partNoSuffix: s.partNoSuffix, surchargeAmount: s.unitPrice });
            });

            await quoteActions.OverrideSurchargesAsync(quote.oKey, surchargeOverrideInfos);
            await quoteActions.LoadQuoteAsync(quote.oKey);
            wait.Show(false);
        }
    }, [quote, wait, quoteActions]);

    const overrideTaxes = useCallback(async (originalState: ITaxesInfo, newState: ITaxesInfo) => {
        setTaxesDialogVisible(false);

        if (quote?.oKey) {
            wait.Show(true);

            const taxOverrideInfos: ITaxOverrideInfo[] = [];

            newState.details.forEach((s) => {
                taxOverrideInfos.push({ index: s.index, percent: s.percent });
            });

            await quoteActions.OverrideTaxesAsync(quote.oKey, taxOverrideInfos);
            await quoteActions.LoadQuoteAsync(quote.oKey);
            wait.Show(false);
        }
    }, [quote, wait, quoteActions]);

    const onSurchargeClick = useCallback(() => {
        setSurchargeDialogVisible(true);
    }, [setSurchargeDialogVisible]);

    const onTaxClick = useCallback(async () => {
        if (quote?.oKey) {
            wait.Show(true);
            await quoteActions.LoadTaxDetailsAsync(quote.oKey);
            wait.Show(false);
            setTaxesDialogVisible(true);
        }
    }, [quote, quoteActions, wait]);

    const renewQuote = useCallback(async (preservePrice: boolean) => {
        if (quote && quote.oKey) {
            wait.Show(true);
            await quoteActions.RenewQuoteAsync(quote.oKey, preservePrice);
            await quoteActions.LoadQuoteAsync(quote.oKey);
            wait.Show(false);
        }
    }, [quote, quoteActions, wait]);

    const navigateToDashboard = useCallback(() => {
        if (user.isAccessedExternal) {
            // attempt to close the window (tab), but this may only work if the window was opened by a script
            window.open('about:blank', '_self');
            window.close();
        }
        else if (user.HasRole(RoleEnum.CSR)) {
            navigate(`/maintenance`);
        }
        else {
            navigate(`/dashboard`);
        }
    }, [navigate, user]);

    const handleFinishClick = useCallback(() => {
        navigateToDashboard()
    }, [navigateToDashboard]);

    const quoteHasRelatedQuote: boolean = useMemo(() => {
        if (quote) {
            if (quote.dealerOrderNumber && quote.dealerOKey)
                return true;
            else if (quote.contractorOrderNumber && quote.contractorOKey)
                return true;
            else
                return false;
        }
        return false;
    }, [quote]);

    const quoteHasRelatedOrder: boolean = useMemo(() => {
        return quote?.mfgOKey !== undefined && quote.mfgOKey > 0 && mfgOrderNumber !== undefined;
    }, [quote, mfgOrderNumber]);

    if (!quote || !permissions || !lock) {
        return <LoadingLinearProgress />;
    }

    return <>

        <Container maxWidth="xl">

            <Stack direction="column" gap={1} mt={1}>
                <Grid container direction={{ xs: "column-reverse", md: (contractor || !lock) ? "row" : "column-reverse", lg: "row" }} gap={1}>
                    <Grid item display="flex">
                        <Box display="flex" flexDirection="row" gap={1} flexWrap="wrap">
                            {!contractor &&
                                <MfgCustomerCard />
                            }
                            <WebCustomerCard />
                        </Box>
                    </Grid>
                    <Grid item xs display="flex" alignSelf="flex-start">
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Stack direction="row" spacing={1} justifyContent={{ xs: "flex-start", md: (contractor || !lock) ? "flex-end" : "flex-start", lg: "flex-end" }}>
                                    <QuoteHeaderActions
                                        quote={quote}
                                        permissions={permissions}
                                        lock={lock}
                                        navigateToDashboard={navigateToDashboard}
                                        quoteHasRelatedLinks={quoteHasRelatedOrder || quoteHasRelatedQuote}
                                        setRelatedLinksDialogOpen={setRelatedLinksDialogVisible}
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" flexWrap="wrap" gap={1} justifyContent={{ xs: "flex-start", md: (contractor || !lock) ? "flex-end" : "flex-start", lg: "flex-end" }}>
                                    <QuoteLockChip 
                                        lock={lock}
                                        onAcquireLock={onAcquireLock}
                                    />
                                    <SubmittedChip />
                                    <StatusChip />
                                </Stack>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
                <ExpirationCard
                    renewQuote={renewQuote}
                />
                <Box display="flex">
                    <MoreLessButton moreText={tm.Get("More")} lessText={tm.Get("Less")} viewMore={viewMore} setViewMore={setViewMore} />
                </Box>
                <Collapse in={viewMore}>
                    <Grid container display="flex" direction="row" spacing={1} >
                        <Grid item xs={12} sm={6} md display="flex">
                            <QuoteInfoCard />
                        </Grid>
                        <Grid item xs={12} sm={6} md display="flex">
                            <SalesCard />
                        </Grid>
                        <Grid item xs={12} sm={6} md display="flex">
                            <BillingCard />
                        </Grid>
                        <Grid item xs={12} sm={6} md display="flex">
                            <ShippingCard />
                        </Grid>
                    </Grid>
                </Collapse>
                {userCanModifyQuote &&
                    <AddLineItem />
                }
                {totals &&
                    <LineItemGrid
                        tm={tm}
                        priceVisible={priceVisible}
                        sqFtPriceVisible={sqFtPriceVisible}
                        onSurchargeClick={onSurchargeClick}
                        onTaxClick={onTaxClick}
                    />
                }
                <FooterCard />

            </Stack>

        </Container>

        <SurchargeDialog
            dialogVisible={surchargeDialogVisible}
            lineItems={lineItems}
            isReadOnly={!userCanModifyPricing}
            viewCost={costVisible}
            onCancel={() => setSurchargeDialogVisible(false)}
            onSubmit={overrideSurcharges}
        />

        <RelatedLinksDialog
            hasRelatedOrder={quoteHasRelatedOrder}
            mfgOrderNumber={mfgOrderNumber}
            hasRelatedQuote={quoteHasRelatedQuote}
            isOpen={relatedLinksDialogVisible}
            onClose={() => setRelatedLinksDialogVisible(false)}
        />

        {
            taxDetails &&
            <TaxesDialog
                dialogVisible={taxesDialogVisible}
                isReadOnly={!userCanModifyTaxes}
                taxDetails={taxDetails}
                onCancel={() => setTaxesDialogVisible(false)}
                onSubmit={overrideTaxes}
            />
        }

    </>
};

export default QuoteEntry;