import { Autocomplete, Box, DialogActions, DialogContent, FormControl, Grid, Stack, TextField, Typography } from "@mui/material";
import OKCancelControl from "components/Common/OKCancelControl";
import useDimensionValue from "components/OptionsWizard/ItemProperties/useDimensionValue";
import { PartDefaults } from "helpers/context/Parts/usePartDefaults";
import Format, { ICallsizeArgs, ImperialFormatModeEnum } from "helpers/fv.format";
import useFormatHelper from "helpers/hooks/useFormatHelper";
import { useTranslations } from "@fenetech/translations";
import { IPartCallSize, IPartCallSizePresets } from "helpers/interfaces";
import { IMeasurementType } from "models/IMeasurementType";
import React, { useRef, useState, useEffect } from 'react';
import useWizardState from "../WebDesigner/useWizardState";
import { useEffectOnLoad } from "helpers/hooks/useEffectOnLoad";


interface IProps {
    newItem: boolean;
    measurementType: IMeasurementType,
    initialState: IItemProperties,
    itemInfo: IItemInformation,
    onCancel: () => void,
    onSubmit: (originalState: IItemProperties, newState: IItemProperties) => void;
};

export interface IItemProperties {
    qty: number;
    callSize: string | null;
    width: number;
    height: number;
    thickness: number;
    comment: string;
}

export interface IItemInformation {
    isSubLineItem: boolean;
    existingItem: boolean;
    defaults: PartDefaults;
    callSizes: IPartCallSizePresets | null;
}



const ItemProperties: React.FC<IProps> = ({ newItem, measurementType, initialState, itemInfo, onCancel, onSubmit }: IProps) => {

    const tm = useTranslations();
    const wizardState = useWizardState();

    const defaults = itemInfo.defaults;
    const partCallSizePresets = itemInfo.callSizes;

    const qtyInput = useRef<HTMLInputElement>(null);
    const formatMethods = useFormatHelper();


    const [qty, setQty] = useState<number>(initialState.qty);

    const [callSize, setCallsize] = useState<string>(() => {

        let callSizes = itemInfo.callSizes;

        let callSize: string = initialState.callSize ?? "";

        if (callSizes && callSizes.callSizes.length > 0) {
            if (newItem) {
                let defaultCallsize = callSizes.callSizes.filter(c => c.default);
                if (defaultCallsize.length > 0) {
                    callSize = defaultCallsize[0].callSize;
                } else {
                    if (callSizes.locked) {
                        callSize = callSizes.callSizes[0].callSize;
                    } else {
                        callSize = "";
                    }
                }
            } 
        }

        return callSize;

    });
    const [displayCallSize, setDisplayCallSize] = useState<string>("");

    const callSizeSelectChosen = React.useMemo(() => {
        return partCallSizePresets !== null && partCallSizePresets.callSizes.length > 0 && callSize !== "";
    }, [callSize, partCallSizePresets]);

    const callSizeLocked = React.useMemo<boolean>(() => {
        if (partCallSizePresets && partCallSizePresets.callSizes.length > 0 && partCallSizePresets.locked) {
            return true;
        } else {
            return false;
        }
    }, [partCallSizePresets]);


    const [width, widthString, handleWidthChange, handleWidthBlur, setWidth, setWidthString]
        = useDimensionValue(initialState.width, measurementType.setID, formatMethods, setCallsize);

    const [height, heightString, handleHeightChange, handleHeightBlur, setHeight, setHeightString]
        = useDimensionValue(initialState.height, measurementType.setID, formatMethods, setCallsize);

    const [thickness, thicknessString, handleThicknessChange, handleThicknessBlur]
        = useDimensionValue(initialState.thickness, measurementType.setID, formatMethods, setCallsize);

    const [comment, setComment] = React.useState<string>(initialState.comment);

    useEffect(() => {
        setDisplayCallSize(callSize);
    }, [callSize]);

    useEffect(() => {
        if (qtyInput?.current) {
            qtyInput.current.focus();
            qtyInput.current.select();
        }
    }, [qtyInput]);

    const handleQtyChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let newQty = parseInt(e.target.value);
        if (isNaN(newQty)) {
            newQty = 0;
        }
        setQty(newQty);
    };

    const handleQtyBlur = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let newQty = parseInt(e.target.value);
        if (isNaN(newQty) || newQty < 1)
            newQty = 1;

        setQty(newQty);
    };

    const handleCallSizeTextChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setDisplayCallSize(e.target.value);
    };

    const handleCallSizeTextBlur = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {

        let newCallSize: string = e.target.value;
        if (newCallSize === callSize) {
            return;
        }

        const currentWidth = width;
        const currentHeight = height;

        let newWidth = currentWidth;
        let newHeight = currentHeight;

        let callsizeArgs: ICallsizeArgs = {
            callSize: newCallSize,
            width: currentWidth,
            height: currentHeight,
        };

        if (Format.formatCallSize(callsizeArgs)) {
            //valid
            newWidth = callsizeArgs.width;
            newHeight = callsizeArgs.height;
        } else {
            //Invalid
            newCallSize = "";
            newWidth = 0;
            newHeight = 0;
        }


        const newFormattedWidth = formatMethods.formatDimensionText(newWidth, measurementType.setID, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false);
        const newFormattedHeight = formatMethods.formatDimensionText(newHeight, measurementType.setID, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false);

        setWidth(newWidth);
        setWidthString(newFormattedWidth);
        setHeight(newHeight);
        setHeightString(newFormattedHeight);

        setCallsize(newCallSize);
        setDisplayCallSize(newCallSize);
    };

    const handleCallSizeSelectChange = (event: any, data: IPartCallSize | null) => {

        const newCallSize: string = data?.callSize ?? "";

        const currentWidth = width;
        const currentHeight = height;

        let newWidth = currentWidth;
        let newHeight = currentHeight;

        if (newCallSize) {
            if (itemInfo.callSizes) {
                const selectedCallSize = itemInfo.callSizes.callSizes.filter(cs => cs.callSize === newCallSize);
                if (selectedCallSize.length > 0) {
                    newWidth = selectedCallSize[0].width;
                    newHeight = selectedCallSize[0].height;
                }
            }
        } else {
            newWidth = 0;
            newHeight = 0;
        }

        const newFormattedWidth = formatMethods.formatDimensionText(newWidth, measurementType.setID, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false);
        const newFormattedHeight = formatMethods.formatDimensionText(newHeight, measurementType.setID, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false);


        setWidth(newWidth);
        setWidthString(newFormattedWidth);
        setHeight(newHeight);
        setHeightString(newFormattedHeight);
        setCallsize(newCallSize);

    };


    const selectedCallSize = React.useCallback(() => {
        if (partCallSizePresets && partCallSizePresets.callSizes && partCallSizePresets.callSizes.length > 0) {
            if (callSize) {
                const item = partCallSizePresets.callSizes.find(cs => cs.callSize === callSize);
                return item ?? null;
            } else {
                return null;
            }

        }
        return null;
    }, [callSize, partCallSizePresets]);

    const handleSubmit = React.useCallback(() => {

        const newState: IItemProperties = {
            qty: isNaN(qty) ? 1 : qty,
            callSize,
            width,
            height,
            thickness,
            comment,
        };

        onSubmit(initialState, newState);

    }, [initialState, onSubmit, callSize, width, height, thickness, qty, comment]);

    useEffectOnLoad(() => {
        if (callSizeSelectChosen) {
            handleCallSizeSelectChange(undefined, partCallSizePresets?.callSizes.find((c) => c.callSize === callSize) ?? null);
        }
    });

    let measurementLabel: string;

    if (measurementType.setID === 1)
        measurementLabel = tm.Get("inches");
    else
        measurementLabel = tm.Get("mm");


    return <>
        <DialogContent>
            <Box display="flex" flexDirection="column" mt={1}>
                <Grid container direction="column" rowSpacing={1} justifyItems="stretch">
                    {defaults.QuantityEnabled() &&
                        <Grid item >
                            <TextField onFocus={e => e.target.select()} inputRef={qtyInput} label={tm.Get("Qty")} value={qty} onChange={handleQtyChange} onBlur={handleQtyBlur} fullWidth
                                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                            />

                        </Grid>
                    }

                    {defaults.CallSizeEnabled() &&
                        <Grid item>
                            <FormControl id="callsize" fullWidth>
                                {partCallSizePresets && partCallSizePresets.callSizes.length > 0 ?
                                    <Autocomplete
                                        autoComplete
                                        disableClearable={callSizeLocked}
                                        autoSelect
                                        autoHighlight
                                        selectOnFocus
                                        handleHomeEndKeys
                                        disabled={!defaults.Defaults?.shortcutAllowsChangesToDimensions ?? false}
                                        options={partCallSizePresets.callSizes}
                                        getOptionLabel={(cs: IPartCallSize) => cs.callSize}
                                        isOptionEqualToValue={(cs: IPartCallSize, value: IPartCallSize) => cs.callSize === value.callSize}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={tm.Get("Call Size")}
                                                InputProps={{
                                                    ...params.InputProps,
                                                }}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    inputMode: wizardState.userPreferences.enableMobileKeyboard ? "text" : "none"
                                                }}
                                            />
                                        )}
                                        onChange={handleCallSizeSelectChange}
                                        value={selectedCallSize()}
                                    />
                                    :
                                    <TextField onFocus={e => e.target.select()} label={tm.Get("Call Size")} value={displayCallSize} onChange={handleCallSizeTextChange} onBlur={handleCallSizeTextBlur} fullWidth
                                        disabled={!defaults.Defaults?.shortcutAllowsChangesToDimensions ?? false} />
                                }
                            </FormControl>

                        </Grid>
                    }

                    {defaults.WidthEnabled() &&
                        <Grid item>
                            <Stack direction={"row"} alignItems="center" justifyContent="left" spacing={1}  >
                                <TextField onFocus={e => e.target.select()} label={tm.Get("Width")} value={widthString} onChange={handleWidthChange} onBlur={handleWidthBlur} fullWidth
                                    disabled={callSizeLocked || (!defaults.Defaults?.shortcutAllowsChangesToDimensions ?? false) || callSizeSelectChosen}
                                    InputProps={{ endAdornment: <Typography>{measurementLabel}</Typography> }}
                                />
                            </Stack>
                        </Grid>
                    }

                    {defaults.HeightEnabled() &&
                        <Grid item>
                            <Stack direction={"row"} alignItems="center" justifyContent="left" spacing={1}  >
                                <TextField onFocus={e => e.target.select()} label={tm.Get("Height")} value={heightString} onChange={handleHeightChange} onBlur={handleHeightBlur} fullWidth
                                    disabled={callSizeLocked || (!defaults.Defaults?.shortcutAllowsChangesToDimensions ?? false) || callSizeSelectChosen}
                                    InputProps={{ endAdornment: <Typography>{measurementLabel}</Typography> }}
                                />
                            </Stack>
                        </Grid>
                    }

                    {defaults.ThicknessEnabled() &&
                        <Grid item>
                            <Stack direction={"row"} alignItems="center" justifyContent="left" spacing={1}  >
                                <TextField onFocus={e => e.target.select()} label={tm.Get("Thickness")} value={thicknessString} onChange={handleThicknessChange} onBlur={handleThicknessBlur} fullWidth
                                    disabled={callSizeLocked || (!defaults.Defaults?.shortcutAllowsChangesToDimensions ?? false)}
                                    InputProps={{ endAdornment: <Typography>{measurementLabel}</Typography> }}
                                />
                            </Stack>
                        </Grid>
                    }

                    {!itemInfo.isSubLineItem &&
                        <Grid item >
                            <TextField onFocus={e => e.target.select()} label={tm.Get("Line Item Comment")} multiline fullWidth minRows={2} value={comment} onChange={(e) => setComment(e.target.value)} />
                        </Grid>
                    }
                </Grid>
            </Box>
        </DialogContent>
        <DialogActions>
            <OKCancelControl okCommand={handleSubmit} cancelCommand={onCancel} okText={tm.Get(newItem ? "Add" : "Submit")} />
        </DialogActions>
    </>;
}

export default ItemProperties;


