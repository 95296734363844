import isNumeric from "fast-isnumeric";
import { Autocomplete, Box, DialogActions, DialogContent, FormControl, Grid, MenuItem, Stack, TextField, Typography } from "@mui/material";
import useDimensionValue from "components/OptionsWizard/ItemProperties/useDimensionValue";
import { PartDefaults } from "helpers/context/Parts/usePartDefaults";
import Format, { ICallsizeArgs, ImperialFormatModeEnum } from "helpers/fv.format";
import { useTranslations } from "@fenetech/translations";
import { IPartCallSize, IPartCallSizePresets } from "helpers/interfaces";
import { IMeasurementType } from "models/IMeasurementType";
import { OpeningShapeEnum, SizingModeEnum, ShapeDirectionEnum } from "helpers/enums";
import React, { useRef, useState, useEffect } from 'react';
import { IOpeningDesignerOpeningSubLineItemInfo } from "components/OptionsWizard/interfaces";
import useFormatHelper from "helpers/hooks/useFormatHelper";
import OKCancelControl from "components/Common/OKCancelControl";
import useWizardState from "../WebDesigner/useWizardState";


interface IProps {
    measurementType: IMeasurementType,
    initialState: IODOpeningProperties,
    itemInfo: IOpeningInformation,
    onCancel: () => void,
    onSubmit: (originalState: IODOpeningProperties, newState: IODOpeningProperties) => void;
};

export interface IODOpeningProperties extends IOpeningDesignerOpeningSubLineItemInfo {
    comment: string;
}

export interface IOpeningInformation {
    defaults: PartDefaults;
    callSizes: IPartCallSizePresets | null;
}


const ODOpeningProperties: React.FC<IProps> = ({ measurementType, initialState, itemInfo, onCancel, onSubmit }: IProps) => {

    const tm = useTranslations();
    const wizardState = useWizardState();
    const defaults = itemInfo.defaults;

    const partCallSizePresets = itemInfo.callSizes;

    const sizingInput = useRef<HTMLSelectElement>(null);
    const formatMethods = useFormatHelper();


    const [sizing, setSizing] = useState<SizingModeEnum>(initialState.sizing);

    const [callSize, setCallsize] = useState<string>(initialState.callSize ?? "");
    const [displayCallSize, setDisplayCallSize] = useState<string>("");

    const callSizeSelectChosen = React.useMemo(() => {
        return partCallSizePresets !== null && partCallSizePresets.callSizes.length > 0 && callSize !== "";
    }, [callSize, partCallSizePresets]);

    const callSizeLocked = React.useMemo<boolean>(() => {
        if (partCallSizePresets && partCallSizePresets.callSizes.length > 0 && partCallSizePresets.locked) {
            return true;
        } else {
            return false;
        }
    }, [partCallSizePresets]);

    const [width, widthString, handleWidthChange, handleWidthBlur, setWidth, setWidthString]
        = useDimensionValue(initialState.width, measurementType.setID, formatMethods, setCallsize);

    const [height, heightString, handleHeightChange, handleHeightBlur, setHeight, setHeightString]
        = useDimensionValue(initialState.height, measurementType.setID, formatMethods, setCallsize);

    const [shape, setShape] = useState<OpeningShapeEnum>(initialState.shape);

    const [comment, setComment] = React.useState<string>(initialState.comment);

    const [direction, setDirection] = useState<ShapeDirectionEnum>(initialState.direction);

    const [legHeight, legHeightString, handleLegHeightChange, handleLegHeightBlur, ,]
        = useDimensionValue(initialState.legHeight, measurementType.setID, formatMethods, (s: string) => { return });

    const [radius, radiusString, handleRadiusChange, handleRadiusBlur, ,]
        = useDimensionValue(initialState.radius, measurementType.setID, formatMethods, (s: string) => { return });

    useEffect(() => {
        setDisplayCallSize(callSize);
    }, [callSize]);

    useEffect(() => {
        if (sizingInput?.current) {
            sizingInput.current.focus();
        }
    }, [sizingInput]);

    const handleSizingChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (isNumeric(e.target.value)) {
            setSizing(parseInt(e.target.value));
        }
    };

    const handleShapeChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (isNumeric(e.target.value)) {
            setShape(parseInt(e.target.value));
        }
    };

    const handleDirectionChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (isNumeric(e.target.value)) {
            setDirection(parseInt(e.target.value));
        }
    };

    const handleCallSizeTextChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setDisplayCallSize(e.target.value);
    };

    const handleCallSizeTextBlur = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {

        let newCallSize: string = e.target.value;
        if (newCallSize === callSize) {
            return;
        }

        const currentWidth = width;
        const currentHeight = height;

        let newWidth = currentWidth;
        let newHeight = currentHeight;

        let callsizeArgs: ICallsizeArgs = {
            callSize: newCallSize,
            width: currentWidth,
            height: currentHeight,
        };

        if (Format.formatCallSize(callsizeArgs)) {
            //valid
            newWidth = callsizeArgs.width;
            newHeight = callsizeArgs.height;
        } else {
            //Invalid
            newCallSize = "";
            newWidth = 0;
            newHeight = 0;
        }

        const newFormattedWidth = formatMethods.formatDimensionText(newWidth, measurementType.setID, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false);
        const newFormattedHeight = formatMethods.formatDimensionText(newHeight, measurementType.setID, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false);

        setWidth(newWidth);
        setWidthString(newFormattedWidth);
        setHeight(newHeight);
        setHeightString(newFormattedHeight);

        setCallsize(newCallSize);
        setDisplayCallSize(newCallSize);
    };

    const handleCallSizeSelectChange = (event: any, data: IPartCallSize | null) => {

        const newCallSize: string = data?.callSize ?? "";

        const currentWidth = width;
        const currentHeight = height;

        let newWidth = currentWidth;
        let newHeight = currentHeight;

        if (newCallSize) {
            if (itemInfo.callSizes) {
                const selectedCallSize = itemInfo.callSizes.callSizes.filter(cs => cs.callSize === newCallSize);
                if (selectedCallSize.length > 0) {
                    newWidth = selectedCallSize[0].width;
                    newHeight = selectedCallSize[0].height;
                }
            }
        } else {
            newWidth = 0;
            newHeight = 0;
        }

        const newFormattedWidth = formatMethods.formatDimensionText(newWidth, measurementType.setID, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false);
        const newFormattedHeight = formatMethods.formatDimensionText(newHeight, measurementType.setID, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false);

        setWidth(newWidth);
        setWidthString(newFormattedWidth);
        setHeight(newHeight);
        setHeightString(newFormattedHeight);
        setCallsize(newCallSize);

    };

    const selectedCallSize = React.useCallback(() => {
        if (partCallSizePresets && partCallSizePresets.callSizes && partCallSizePresets.callSizes.length > 0) {
            if (callSize) {
                const item = partCallSizePresets.callSizes.find(cs => cs.callSize === callSize);
                return item ?? null;
            } else {
                return null;
            }

        }
        return null;
    }, [callSize, partCallSizePresets]);

    const handleSubmit = React.useCallback(() => {

        const newState: IODOpeningProperties = {
            sizing,
            callSize,
            width,
            height,
            shape,
            comment,
            direction,
            legHeight,
            radius,
        };

        onSubmit(initialState, newState);

    }, [initialState, onSubmit, callSize, width, height, shape, sizing, comment, direction, legHeight, radius]);


    let measurementLabel: string;

    if (measurementType.setID === 1)
        measurementLabel = tm.Get("inches");
    else
        measurementLabel = tm.Get("mm");


    return <>
        <DialogContent>
            <Box display="flex" flexDirection="column" mt={1}>
                <Grid container direction="column" rowSpacing={1} justifyItems="stretch">
                    <Grid item >
                        <TextField inputRef={sizingInput} label={tm.Get("Sizing")} value={sizing} onChange={handleSizingChange} fullWidth select>
                            <MenuItem value={SizingModeEnum.Fixed}>{tm.Get("Fixed")}</MenuItem>
                            <MenuItem value={SizingModeEnum.Grow}>{tm.Get("Grow")}</MenuItem>
                        </TextField>
                    </Grid>

                    {defaults.CallSizeEnabled() &&
                        <Grid item>
                            <FormControl id="callsize" fullWidth>
                                {partCallSizePresets && partCallSizePresets.callSizes.length > 0 ?
                                    <Autocomplete
                                        autoComplete
                                        disableClearable={callSizeLocked}
                                        autoSelect
                                        autoHighlight
                                        selectOnFocus
                                        handleHomeEndKeys
                                        options={partCallSizePresets.callSizes}
                                        getOptionLabel={(cs: IPartCallSize) => cs.callSize}
                                        isOptionEqualToValue={(cs: IPartCallSize, value: IPartCallSize) => cs.callSize === value.callSize}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={tm.Get("Call Size")}
                                                InputProps={{
                                                    ...params.InputProps,
                                                }}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    inputMode: wizardState.userPreferences.enableMobileKeyboard ? "text" : "none"
                                                }}
                                            />
                                        )}
                                        onChange={handleCallSizeSelectChange}
                                        value={selectedCallSize()}
                                    />
                                    :
                                    <TextField onFocus={e => e.target.select()} label={tm.Get("Call Size")} value={displayCallSize} onChange={handleCallSizeTextChange} onBlur={handleCallSizeTextBlur} fullWidth />
                                }
                            </FormControl>

                        </Grid>
                    }

                    {defaults.WidthEnabled() &&
                        <Grid item>
                            <Stack direction={"row"} alignItems="center" justifyContent="left" spacing={1}  >
                                <TextField onFocus={e => e.target.select()} label={tm.Get("Width")} value={widthString} onChange={handleWidthChange} onBlur={handleWidthBlur} fullWidth
                                    disabled={callSizeLocked || callSizeSelectChosen}
                                    InputProps={{ endAdornment: <Typography>{measurementLabel}</Typography> }}
                                />

                            </Stack>
                        </Grid>
                    }

                    {defaults.HeightEnabled() &&
                        <Grid item>
                            <Stack direction={"row"} alignItems="center" justifyContent="left" spacing={1}  >
                                <TextField onFocus={e => e.target.select()} label={tm.Get("Height")} value={heightString} onChange={handleHeightChange} onBlur={handleHeightBlur} fullWidth
                                    disabled={callSizeLocked || callSizeSelectChosen}
                                    InputProps={{ endAdornment: <Typography>{measurementLabel}</Typography> }}
                                />
                            </Stack>
                        </Grid>
                    }

                    <Grid item>
                        <TextField label={tm.Get("Shape")} value={shape} onChange={handleShapeChange} fullWidth select>
                            <MenuItem value={OpeningShapeEnum.Standard}>{tm.Get("Standard")}</MenuItem>
                            <MenuItem value={OpeningShapeEnum.Rake}>{tm.Get("Rake")}</MenuItem>
                            <MenuItem value={OpeningShapeEnum.Gable}>{tm.Get("Gable")}</MenuItem>
                            <MenuItem value={OpeningShapeEnum.Arc}>{tm.Get("Arc")}</MenuItem>
                            <MenuItem value={OpeningShapeEnum.HalfArc}>{tm.Get("Half Arc")}</MenuItem>
                        </TextField>
                    </Grid>

                    {(shape === OpeningShapeEnum.Rake || shape === OpeningShapeEnum.HalfArc) &&
                        <Grid item>
                            <TextField onFocus={e => e.target.select()} label={tm.Get("Direction")} value={direction} onChange={handleDirectionChange} fullWidth select>
                                <MenuItem value={ShapeDirectionEnum.Left}>{tm.Get("Left")}</MenuItem>
                                <MenuItem value={ShapeDirectionEnum.Right}>{tm.Get("Right")}</MenuItem>
                            </TextField>
                        </Grid>
                    }

                    {shape !== OpeningShapeEnum.Standard &&
                        <Grid item>
                            <Stack direction={"row"} alignItems="center" justifyContent="left" spacing={1}  >
                                <TextField onFocus={e => e.target.select()} label={tm.Get("Leg")} value={legHeightString} onChange={handleLegHeightChange} onBlur={handleLegHeightBlur} fullWidth
                                    InputProps={{ endAdornment: <Typography>{measurementLabel}</Typography> }}
                                />
                            </Stack>
                        </Grid>
                    }

                    {shape === OpeningShapeEnum.HalfArc &&
                        <Grid item>
                            <Stack direction={"row"} alignItems="center" justifyContent="left" spacing={1}  >
                                <TextField onFocus={e => e.target.select()} label={tm.Get("Radius")} value={radiusString} onChange={handleRadiusChange} onBlur={handleRadiusBlur} fullWidth
                                    InputProps={{ endAdornment: <Typography>{measurementLabel}</Typography> }}
                                />
                            </Stack>
                        </Grid>
                    }
                    <Grid item >
                        <TextField onFocus={e => e.target.select()} label={tm.Get("Line Item Comment")} multiline fullWidth minRows={2} value={comment} onChange={(e) => setComment(e.target.value)} />
                    </Grid>
                </Grid>
            </Box>
        </DialogContent>
        <DialogActions>
            <OKCancelControl okCommand={handleSubmit} cancelCommand={onCancel} okText={tm.Get("Update")} />
        </DialogActions>
    </>;
}

export default ODOpeningProperties;