import { ResponsePayloadTypeEnum, ViewTypeEnum } from "./enum";
import { IPostResultItem, IWDCRepsonse } from "./interfaces";
import APIHelper from "helpers/APIHelper";
import { IItemProperties } from "components/OptionsWizard/ItemProperties/ItemProperties";
import { IODOpeningProperties } from "components/OptionsWizard/ItemProperties/ODOpeningProperties";
import { IODSLIProperties } from "components/OptionsWizard/ItemProperties/ODSLIProperties";



export interface ISplitParts {
    parts: ISelectItem[],
    default: string,
}
export interface ISelectItem {
    value: string;
    text: string;
}

export default class OptionsAPI {


    public static async QueryOptionsWizardInitialStateAsync(requestedResult: ResponsePayloadTypeEnum = 0): Promise<Array<IPostResultItem> | null> {

        let webURL: string = 'api/OptionsWizard/InitialLoad';

        const queryParams = { responsePayload: requestedResult };

        const wr = await APIHelper.GetAsync<Array<IPostResultItem>>(webURL, queryParams);
        return wr.Result
    }

    public static async ChangeItemProperties(params: IItemProperties): Promise<Array<IPostResultItem>> {
        let webURL: string = 'api/OptionsWizard/ChangeItemProperties';
        const wr = await APIHelper.PostAsync<IPostResultItem[]>(webURL, undefined, params);
        return wr.Result;
    }

    public static async ChangeODOpeningProperties(params: IODOpeningProperties): Promise<Array<IPostResultItem>> {
        let webURL: string = 'api/OptionsWizard/ChangeODOpeningProperties';
        const wr = await APIHelper.PostAsync<IPostResultItem[]>(webURL, undefined, params);
        return wr.Result;
    }


    public static async ChangeODSLIProperties(params: IODSLIProperties): Promise<{ success: boolean, result: Array<IPostResultItem> | string }> {
        let webURL: string = 'api/OptionsWizard/ChangeODSLIProperties';
        const wr = await APIHelper.PostAsync<IPostResultItem[]>(webURL, undefined, params, false);

        if (wr.BadRequest) {
            return { success: false, result: (wr.Message as any).Message };
        } else {
            return { success: true, result: wr.Result };
        }
    }


    public static async ChangeDesignerPart(partNo: string, partNoSuffix: string): Promise<Array<IPostResultItem>> {
        let webURL: string = 'api/OptionsWizard/ChangeDesignerPart';
        let params: any = {
            partNo: partNo,
            partNoSuffix: partNoSuffix
        };
        const wr = await APIHelper.PostAsync<IPostResultItem[]>(webURL, undefined, params);
        return wr.Result;
    }

    public static async ChangeSectionSizing(width: number, height: number, callSize: string | null): Promise<Array<IPostResultItem>> {
        let webURL: string = 'api/OptionsWizard/ChangeSectionSizing';

        let params: any = {
            width: width,
            height: height,
            callSize: callSize
        };

        const wr = await APIHelper.PostAsync<IPostResultItem[]>(webURL, undefined, params);
        return wr.Result;
    }

    public static async SelectDesignerSectionAsync(sli: number): Promise<Array<IPostResultItem> | null> {
        let webURL: string = 'api/OptionsWizard/SelectDesignerItem';

        let params: any = {
            sublineitem: sli
        };

        const wr = await APIHelper.PostAsync<IPostResultItem[]>(webURL, undefined, params);
        return wr.Result;
    }


    public static async HandleMultiSelectPartOrOptionsClickAsync(doParts: boolean, sublineitems: Array<number>): Promise<IPostResultItem[] | null> {

        let params: any = {
            changingPart: doParts,
            subLineItems: sublineitems
        };

        let webURL: string = "api/Optionswizard/CommitMultiSelectAction";

        const wr = await APIHelper.PostAsync<IPostResultItem[]>(webURL, undefined, params, false);

        if (wr.BadRequest)
            return null;
        else
            return wr.Result;

    }



    public static async SubmitCodeSelect(sequence: number, code: string): Promise<Array<IPostResultItem> | null> {
        let webURL: string = 'api/OptionsWizard/SelectCode';

        let params: any = {
            code: code,
            sequence: sequence
        };
        const wr = await APIHelper.PostAsync<IPostResultItem[]>(webURL, undefined, params);
        return wr.Result;
    }

    public static async SubmitCodeValueSelect(sequence: number, code: string, value: string): Promise<Array<IPostResultItem> | null> {
        let webURL: string = 'api/OptionsWizard/SelectCodeValue';

        let params: any = {
            code: code,
            sequence: sequence,
            value: value
        };

        const wr = await APIHelper.PostAsync<IPostResultItem[]>(webURL, undefined, params);
        return wr.Result;
    }

    public static async SubmitShapeCodeSelect(shapeNum: number): Promise<Array<IPostResultItem> | null> {
        let webURL: string = 'api/OptionsWizard/SelectShapeCode';

        const wr = await APIHelper.PostAsync<IPostResultItem[]>(webURL, undefined, shapeNum);
        return wr.Result;
    }

    public static async SubmitShapeCodeWithParamsSelect(): Promise<Array<IPostResultItem> | null> {
        let webURL: string = 'api/OptionsWizard/SelectShapeCodeWithParams';

        const wr = await APIHelper.PostAsync<IPostResultItem[]>(webURL);
        return wr.Result;
    }

    public static async RemoveCode(sequence: number, code: string): Promise<Array<IPostResultItem> | null> {
        let webURL: string = 'api/OptionsWizard/RemoveCode';

        let params: any = {
            code: code,
            sequence: sequence
        };

        const wr = await APIHelper.PostAsync<IPostResultItem[]>(webURL, undefined, params);
        return wr.Result;
    }

    public static async CommitCurrentAction(): Promise<Array<IPostResultItem> | null> {
        let webURL: string = 'api/OptionsWizard/CommitCurrentAction';

        const params = null;

        const wr = await APIHelper.PostAsync<Array<IPostResultItem>>(webURL, undefined, params);
        return wr.Result;
    }

    public static async RunExpressions(sequence: number, code: string): Promise<Array<IPostResultItem> | null> {
        let webURL: string = 'api/OptionsWizard/RunExpressions';

        let params: any = {
            code: code,
            sequence: sequence
        };

        const wr = await APIHelper.PostAsync<IPostResultItem[]>(webURL, undefined, params);
        return wr.Result;
    }

    public static async SetDefaultOptionsWizardDisplayView(view: ViewTypeEnum, odKey: number): Promise<void> {
        let webURL: string = 'api/OptionsWizard/SetDefaultDisplayView';

        let params: any = { view, odKey };

        await APIHelper.PostAsync(webURL, undefined, params);
    }

    public static async ResetDesigner(): Promise<IWDCRepsonse> {
        let webURL: string = 'api/OptionsWizard/ODResetDesigner';
        return await (await APIHelper.PostAsync<IWDCRepsonse>(webURL)).Result;
    }

    public static async PinSection(pin: boolean): Promise<IWDCRepsonse> {
        let webURL: string = 'api/OptionsWizard/ODPinSection';
        let params = { pin };
        return await (await APIHelper.PostAsync<IWDCRepsonse>(webURL, undefined, params)).Result;
    }

    public static async DeleteSection(): Promise<IWDCRepsonse> {
        let webURL: string = 'api/OptionsWizard/ODDeleteSection';
        return await (await APIHelper.PostAsync<IWDCRepsonse>(webURL)).Result;
    }

    public static async GetSplitParts(sideBySide: boolean): Promise<ISplitParts> {
        let webURL: string = 'api/OptionsWizard/ODSplitParts';
        let params = { sideBySide };
        return (await APIHelper.PostAsync<ISplitParts>(webURL, undefined, params)).Result;
    }

    public static async SplitCurrentSection(qty: number, part: string, sideBySide: boolean) {
        let webURL: string = 'api/OptionsWizard/SplitSection';
        let params = {
            sectionCount: qty,
            selectedPart: part,
            sideBySide: sideBySide,
        };
        return (await APIHelper.PostAsync<IWDCRepsonse>(webURL, undefined, params)).Result;
    }

}
