import React from "react";
import { useState } from "react";
import Format, { ImperialFormatModeEnum } from "helpers/fv.format";
import useLocaleNumberFormatter from "helpers/hooks/useLocaleNumberFormatter";

type DimensionChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
type DimensionBlurHandler = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void;


export default function useDimensionValue(
    initialValue: number, measurementSetID: number, formatMethods: Format,
    setCallsize: React.Dispatch<string>,
    onChange?: (newValue: any) => void,
): [number, string, DimensionChangeHandler, DimensionBlurHandler, React.Dispatch<number>, React.Dispatch<string>] {

    const [dimension, setDimension] = useState<number>(initialValue);
    const [dimensionString, setDimensionString] = useState<string>(formatMethods.formatDimensionText(initialValue, measurementSetID, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false));
    const lnf = useLocaleNumberFormatter();

    const handleDimensionChange = React.useCallback((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setDimensionString(e.target.value);
    }, [setDimensionString]);


    const handleDimensionBlur = React.useCallback((e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {

        let stringValue: string = e.target.value;
        let numericValue = lnf.Parse(stringValue);

        if (isNaN(numericValue)) {
            numericValue = Format.fracToDec(stringValue) ?? 0;
        }

        stringValue = formatMethods.formatDimensionText(numericValue, measurementSetID, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false);

        if (dimension !== numericValue || dimensionString !== stringValue) {
            setDimensionString(stringValue);
            setDimension(numericValue);
            setCallsize("");
            if (onChange) {
                onChange(numericValue);
            }
        }

    }, [dimension, dimensionString, measurementSetID, setDimensionString, setDimension, setCallsize, formatMethods, lnf, onChange]);


    return [dimension, dimensionString, handleDimensionChange, handleDimensionBlur, setDimension, setDimensionString];

}
