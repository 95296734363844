import { useState, useMemo, useEffect, useCallback } from "react";
import { Paper, Box, Typography, Grid, useTheme, Button, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { Autorenew } from "@mui/icons-material";
import { DateTime } from "luxon";

import AlertDialog from "components/Common/AlertDialog";

import { useTranslations } from "@fenetech/translations";
import useQuoteData from "components/Quotes/useQuoteData";

import Format from "helpers/fv.format";
import useQuoteActions from "components/Quotes/useQuoteActions";
import { ThemeColorEnum } from "helpers/enums";

interface IProps {
    renewQuote: (preservePrice: boolean) => void,
}

function ExpirationCard({ renewQuote }: IProps) {
    const tm = useTranslations();
    const theme = useTheme();
    const { quote } = useQuoteData();
    const [canRenewQuote, setCanRewQuote] = useState<boolean | undefined>(undefined);
    const [isPromptOpen, setIsPromptOpen] = useState<boolean>(false);
    const [preservePrice, setPreservePrice] = useState<boolean>(false);
    const quoteActions = useQuoteActions();

    const isQuoteExpired = useMemo(() => {

        var today = DateTime.now();

        if (quote && quote.expirationDate) {
            var quoteExpiration = DateTime.fromJSDate(new Date(quote.expirationDate));
            return today >= quoteExpiration;
        }

        return undefined;

    }, [quote]);

    const loadIsRenewQuoteAllowedAsync = useCallback(async (oKey: number) => {
        const isAllowed = await quoteActions.IsRenewQuoteAllowedAsync(oKey);
        setCanRewQuote(isAllowed);
    }, [quoteActions]);

    useEffect(() => {
        if (quote && quote.oKey && isQuoteExpired) {
            loadIsRenewQuoteAllowedAsync(quote.oKey);
        }
    }, [quote, isQuoteExpired, loadIsRenewQuoteAllowedAsync]);

    const renewQuoteCallback = useCallback(() => {
        setPreservePrice(true);
        setIsPromptOpen(true);
    }, []);

    const renewQuotePromptCallback = useCallback((result: boolean) => {
        setIsPromptOpen(false);
        if (result) {
            renewQuote(preservePrice);
        }
    }, [renewQuote, preservePrice]);


    if (!isQuoteExpired) {
        return <></>;
    }

    return <>
        <Paper>
            <Box sx={{ margin: 1 }} display="flex">
                <Grid container direction="row" display="flex" alignItems="center">
                    <Grid item xs>
                        <Typography fontWeight="bold" color={theme.palette.error.dark}>
                            {tm.GetWithParams("This quote has expired on {0}.", Format.FormatDate(quote?.expirationDate))}
                        </Typography>
                    </Grid>
                    {canRenewQuote &&
                        <Grid item xs="auto">
                            <Button variant="contained" color={ThemeColorEnum.Secondary} startIcon={<Autorenew />} onClick={renewQuoteCallback}>
                                {tm.Get("Renew")}
                            </Button>
                        </Grid>
                    }
                </Grid>
            </Box>
        </Paper>

        <AlertDialog
            open={isPromptOpen}
            handleClose={renewQuotePromptCallback}
            headerText={tm.Get("Renew Quote")}
            yesText={tm.Get("OK")}
            noText={tm.Get("Cancel")}
        >
            <FormControl sx={{minWidth: "185px"}}>
                <RadioGroup
                    defaultValue="preserve"
                    onChange={(_, value) => setPreservePrice(value === "preserve")}
                >
                    <FormControlLabel value="preserve" control={<Radio />} label={tm.Get("Preserve Price")} />
                    <FormControlLabel value="recalc" control={<Radio />} label={tm.Get("Recalulate Price")} />
                </RadioGroup>
            </FormControl>
        </AlertDialog>

    </>

}


export default ExpirationCard;