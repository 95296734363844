import useOrderData from "components/Orders/useOrderData";
import { useTranslations } from "@fenetech/translations";

import { OrderTypesEnum } from "helpers/enums";

export function useOrderTypeText() : string {

    const { order } = useOrderData();
    const tm = useTranslations();

    return order?.orderType === OrderTypesEnum.Pickup ? tm.Get("Pickup") : tm.Get("Order");
}

export default useOrderTypeText;