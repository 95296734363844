import { ApplicationContextProvider } from 'helpers/context/Application/ApplicationContext';
import { UserContextProvider } from 'helpers/context/User/UserContext';
import { PageProvider } from 'helpers/context/Page/PageContext';
import { SelectionValuesContextProvider } from 'helpers/context/SelectionValues/SelectionValuesContext';
import { QuoteContextProvider } from "components/Quotes/QuoteContext";
import ApplicationContextValueAssurance from "helpers/context/Application/ApplicationContextValueAssurance";
import UserContextValueAssurance from "helpers/context/User/UserContextValueAssurance";
import { WizardContextProvider } from "components/OptionsWizard/WizardContext";

const ApplicationProviders: React.FC<React.PropsWithChildren<any>> = (props: React.PropsWithChildren<any>) => {

    return <ApplicationContextProvider>
        <UserContextProvider>
            <ApplicationContextValueAssurance>
                <UserContextValueAssurance>
                    <SelectionValuesContextProvider>
                        <QuoteContextProvider>
                            <WizardContextProvider>
                                <PageProvider>
                                    {props.children}
                                </PageProvider>
                            </WizardContextProvider>
                        </QuoteContextProvider>
                    </SelectionValuesContextProvider>
                </UserContextValueAssurance>
            </ApplicationContextValueAssurance>
        </UserContextProvider>
    </ApplicationContextProvider>
}

export default ApplicationProviders;
