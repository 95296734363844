import ConfiguratorHandler from "helpers/context/Configurator/ConfiguratorHandler";
import React, { PropsWithChildren } from "react";


export interface IConfiguratorInfo {
    embedded: boolean;
    configurator: string | undefined;
};

const initialState: IConfiguratorInfo = {
    embedded: false,
    configurator: undefined,
};


export enum ConfiguratorActionEnum {
    SetEmbedded,
    SetConfigurator
}

export type ConfiguratorAction =
    { action: ConfiguratorActionEnum.SetEmbedded, value: boolean } |
    { action: ConfiguratorActionEnum.SetConfigurator, value: string}


const ConfiguratorReducer: (state: IConfiguratorInfo, action: ConfiguratorAction) => IConfiguratorInfo = (state, action) => {

    switch (action.action) {
        case ConfiguratorActionEnum.SetEmbedded:
            return { ...state, embedded: action.value };
        case ConfiguratorActionEnum.SetConfigurator:
            return { ...state, configurator: action.value}
    }
}

export const ConfiguratorHandlerContext = React.createContext<ConfiguratorHandler | undefined>(undefined);
export const ConfiguratorContext = React.createContext<IConfiguratorInfo>(initialState);

const ConfiguratorContextProvider = (props: PropsWithChildren<any>) => {

    const [configuratorInfo, configuratorDispatch] = React.useReducer(ConfiguratorReducer, initialState)

    const configuratorHandler = React.useMemo(() => {
        return new ConfiguratorHandler(configuratorDispatch)
    }, [configuratorDispatch]);

    return <ConfiguratorContext.Provider value={configuratorInfo}>
        <ConfiguratorHandlerContext.Provider value={configuratorHandler}>
            {props.children}
        </ConfiguratorHandlerContext.Provider>
    </ConfiguratorContext.Provider>

};


export default ConfiguratorContextProvider;