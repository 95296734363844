import * as React from "react";

import WebDesignerHeader from "../WebDesigner/WebDesignerHeader";
import OptionsWizard from "../OptionsWizard/OptionsWizard";
import ItemInfoContainer from "./ItemInfoContainer";

import { ISectionRegion } from "../interfaces";

import { ITranslationManager } from "@fenetech/translations";
import { Container, Grid, Stack } from "@mui/material";
import WizardBottomNav from "components/OptionsWizard/MainWizard/Mobile/WizardBottomNav";
import useWizardState from "components/OptionsWizard/WebDesigner/useWizardState";
import useFormatHelper from "helpers/hooks/useFormatHelper";
import useIsMobile from "helpers/hooks/useIsMobile";
import useWait from "helpers/context/Page/useWait";
import useWizardInteractions from "../useWizardInteractions";
import WizardHelper from "../WizardHelper";

const StandardWizardMain: React.FC<any> = () => {

    const wizardState = useWizardState();

    const format = useFormatHelper();
    const isMobile = useIsMobile();
    const wait = useWait();
    const wizardInteractions = useWizardInteractions();

    React.useEffect(() => {
        wait.Show(wizardState.loading);
    }, [wait, wizardState.loading]);

    const clickRegionHandler = (e: ISectionRegion, tm: ITranslationManager) => {
        if (wizardState.validations && wizardState.webDesigner) {
            if (WizardHelper.AreSubLineItemTabsEnabled(wizardState.validations.requiredOptionSubLineItems, wizardState.webDesigner.currentSection)) {
                wizardInteractions.SelectDesignerItemAsync(e.id);
            }
        }
    }


    if (!wizardState.webDesigner && !wizardState.options) {
        return null;  //if we haven't received any state, don't bother rendering anything. (will lead to flicker and possibly double loading DO image)
    }

    if (isMobile) {

        return <WizardBottomNav clickRegionHandler={clickRegionHandler} />

    } else {

        return <Grid container position="relative">

            {/*Left Pane*/}
            <Grid item xs={8} >
                <Stack direction="column" spacing={1} mt={wizardState.webDesigner ? 0 : 2} mx={1}>
                    <WebDesignerHeader
                        webDesigner={wizardState.webDesigner}
                        validation={wizardState.validations}
                        itemInfo={wizardState.itemInfo}
                    />

                    <OptionsWizard
                        groups={wizardState.groups}
                        userPreferences={wizardState.userPreferences}
                        wizardKey={wizardState.wizardStateKey}
                        readOnlyMode={wizardState.sysInfo !== null && wizardState.sysInfo.readOnlyMode}
                        itemInfo={wizardState.itemInfo}
                    />
                </Stack>
            </Grid>

            {/*Right Pane*/}
            <Grid container item xs={4}  >
                <Container disableGutters >
                    <Container disableGutters sx={{ position: "sticky", top: "40px" }}>
                        <ItemInfoContainer
                            options={wizardState.options}
                            partPreferences={wizardState.partPreferences}
                            validations={wizardState.validations}
                            itemInfo={wizardState.itemInfo}
                            webDesigner={wizardState.webDesigner}
                            displayViewSettings={wizardState.displayViewSettings}
                            focusedCode={wizardState.focusedCode}
                            prices={wizardState.prices}
                            altWizInfo={null}
                            clickRegionHandler={clickRegionHandler}
                            mode={wizardState.wizardMode}
                            format={(format)}
                        />
                    </Container>
                </Container>
            </Grid>

        </Grid>
    }
}

export default StandardWizardMain;