import APIHelper from "helpers/APIHelper";
import { IPartKey, IProductNavigator } from "helpers/interfaces";

export default class ProductNavigatorAPI {
    
    public static async ProductNavigator(oKey: number, getCallSizes: boolean, callSize: string, partList: IPartKey[] | null): Promise<IProductNavigator> {
        const url: string = `api/ProductNavigator/${oKey}`;
        const params = {
            getCallSizes,
            callSize,
            partList
        }
        const response = await APIHelper.PostAsync<IProductNavigator>(url, undefined, params);
        return response.Result;
    }
}