import React from "react";
import ApplicationInfo from "../../../models/ApplicationInfo"
import { ApplicationContext } from "./ApplicationContext";

const defaultInfo = new ApplicationInfo({
    parameters: {
        supportFiles: false,
        maintenancePageSize: null,
        performOrderLocking: false,
        pricingShowDiscountMultiplierInsteadOfPercent: false,
        pricingShowMarkupMultiplierInsteadOfPercent: false,
        customDeliveryDateLabel: null,
        dashboardQuotesFirst: true,
        requireQuoteContact: false,
        orderEntryPOCheckAlpha: false,
        orderEntryPOCheckNumber: false,
        customRequestedDeliveryDateLabel: null,
        requestDateMaxLookAheadDays: 180,
        hideOptionCodes: false,
        showSquareFeet: false,
        displayProdStatus: false,
        displayProdStatusRejectLink: false,
        autoUseProductNavigatorInAlternateWizard: false,
    },
    timeout:30,
    rmaUnlocked: false,
    extendedContractorModeUnlocked: false,
    productNavigatorEnabled: false,
    surchargesConfigured: false,
    engineeringUnits: {
        systemUnitSet: 0,
        unitSets:[],
    },
    currencyCultures: [{
        cultureInfo: "en-US",
        description: "US Dollar",
        decimalPlaces: 2,
        pricingDecimalPlaces: 2,
        minimumCurrencyDenominationForCashRounding: 0.01,
        symbol: "$",
        isBaseline: true
    }]
    
});

export default function useApplicationInfo(): ApplicationInfo {
    const info = React.useContext(ApplicationContext)
    return info ?? defaultInfo;
}
