import * as React from "react";

import OWExpander from "./OWExpander";
import OWQuestions from "./OWQuestions";
import OWTabs from "./OWTabs";

import { GroupDisplayTypeEnum } from "../enum";
import { ILineItemInfo, IUserPreferences } from "../interfaces";
import { IQuestionGroup } from "../models";
import { Stack } from "@mui/material";
import useIsMobile from "helpers/hooks/useIsMobile";
import ItemSize from "components/OptionsWizard/MainWizard/ItemSize";
import OWTabsMobile from "components/OptionsWizard/OptionsWizard/OWTabsMobile";
import useWizardState from "components/OptionsWizard/WebDesigner/useWizardState";
import OptionGroupBox from "./OptionGroupBox";


interface IOptionsWizardProps {
    groups: Array<IQuestionGroup>;
    userPreferences: IUserPreferences;
    wizardKey: number;
    readOnlyMode: boolean;
    itemInfo: ILineItemInfo | null;
}


const OptionsWizard: React.FC<IOptionsWizardProps> = (props: IOptionsWizardProps) => {

    const wizardState = useWizardState();

    const isMobile = useIsMobile();
    const sizingInOptionStructure = isMobile && !wizardState.altWizInfo;

    const renderQuestionContainer = React.useCallback((props: IOptionsWizardProps) => {

        if (props.userPreferences.groupDisplayType === GroupDisplayTypeEnum.Tabs && props.groups && props.groups.length > 0 && props.groups[props.groups.length - 1].groupName !== "") {
            //Having the key based on the wizardKey ensures that the component will be recreated (instead of given new props) when the wiz key changes.
            // this will cause the tabs to reset to their default position on part /sub line item change.

            if (isMobile)
                return [<OWTabsMobile
                    preferences={props.userPreferences}
                    key={"TABS" + props.wizardKey}
                    questionGroups={props.groups}
                    readOnlyMode={props.readOnlyMode}
                    sizingAsGroup={isMobile}
                    itemInfo={props.itemInfo}
                />];
            else
                return [<OWTabs
                    preferences={props.userPreferences}
                    key={"TABS" + props.wizardKey}
                    questionGroups={props.groups}
                    readOnlyMode={props.readOnlyMode}
                    sizingAsGroup={sizingInOptionStructure}
                    itemInfo={props.itemInfo}
                />];
        } else {

            let questions: JSX.Element[];

            if (props.userPreferences.groupDisplayType === GroupDisplayTypeEnum.Expander && props.groups.length > 0 && props.groups[props.groups.length - 1].groupName !== "") {
                questions = props.groups.map((qg: IQuestionGroup) =>
                    <OWExpander
                        preferences={props.userPreferences}
                        key={"GRP_" + qg.groupName}
                        questionGroup={qg}
                        readOnlyMode={props.readOnlyMode}
                    />);

            } else {
                questions = props.groups.map((qg: IQuestionGroup) =>
                    <OWQuestions
                        preferences={props.userPreferences}
                        key={"QSTS_" + qg.groupName}
                        questionGroup={qg}
                        readOnlyMode={props.readOnlyMode}
                    />);
            }

            if (sizingInOptionStructure && props.itemInfo) {
                return [<OptionGroupBox key="ACRD_SIZE" id="ACRD_SIZE" label="">
                    <ItemSize
                        key="GRPS_SIZE"
                    />
                </OptionGroupBox>, ...questions];
            } else {
                return questions;
            }

        }
    }, [sizingInOptionStructure, isMobile]);

    return <Stack spacing={1}>
        {renderQuestionContainer(props)}
    </Stack>;
}

export default OptionsWizard;



