import React from 'react';
import AppRoutes from "AppRoutes";
import MessageBox from "components/Dialog/MessageBox";
import ActionBar from "components/Layout/ActionBar";
import ActionFooter from "components/Layout/ActionFooter";
import ErrorBoundary from "components/Layout/ErrorBoundary";
import Footer from "components/Layout/Footer";
import TopHeader from "components/Layout/TopHeader";
import WaitOverlay from "components/Layout/WaitOverlay";
import useNavigationBlockerActions from "helpers/context/Application/useNavigationBlockerActions";
import useAuthHandler from "helpers/context/Authentication/useAuthHandler";
import useAuthInfo from "helpers/context/Authentication/useAuthInfo";
import useMessageBox from "helpers/context/Page/useMessageBox";
import useUserInfo from "helpers/context/User/useUserInfo";
import { RoleEnum } from "helpers/enums";
import useIsMobile from "helpers/hooks/useIsMobile";
import { useTranslations } from "@fenetech/translations";
import { useLocation, useNavigate } from "react-router-dom";
import useOnlineAckToken from "helpers/hooks/useOnlineAckToken";
import useConfiguratorInfo from "helpers/context/Configurator/useConfiguratorInfo";

interface IProps {

}

const AppAuthorized: React.FC<IProps> = (props: IProps) => {

    const authHandler = useAuthHandler();
    const authInfo = useAuthInfo();
    const configuratorInfo = useConfiguratorInfo();
    const messageBox = useMessageBox();
    const tm = useTranslations();
    const navigate = useNavigate();
    const location = useLocation();
    const userInfo = useUserInfo();
    const navBlockerActions = useNavigationBlockerActions();
    const isMobile = useIsMobile();
    const onlineAckToken = useOnlineAckToken();

    React.useEffect(() => {

        if (authInfo.timedOut) {
            navBlockerActions.Unblock();
            messageBox.Show({
                title: tm.Get("Timeout"),
                message: tm.Get("Your session has expired due to inactivity. You will be redirected to the sign in page."),
                callback: () => {
                    authHandler.SignOutAsync().then(() => {
                        if (userInfo.HasRole(RoleEnum.CSR)) {
                            navigate("/login/csr");
                        }
                    });
                }
            })
        }

        return () => { messageBox.Hide(); }

    }, [authInfo.timedOut, authHandler, tm, messageBox, navBlockerActions, navigate, userInfo])

    // If online ack user is 'logged in' but the URL loses the token, automatically sign the user out
    React.useEffect(() => {
        if (userInfo.HasRole(RoleEnum.OnlineAck) && onlineAckToken === undefined) {
            authHandler.SignOutAsync();
        }
    }, [userInfo, onlineAckToken, authHandler])

    const isOptionWizard = React.useMemo(() => {
        const pageName = location.pathname.replace('/', '');
        return pageName === "quotes/lineitem";
    }, [location]);

    const hideTopHeader = React.useMemo(() => {
        //hide the top header in mobile options wizard because:
        //1) It will maximize the available space for options and images
        //2) Since scroll position is different for each tab it avoids the header from appearing/disappearing when changing tabs
        return configuratorInfo.embedded || (isMobile && isOptionWizard);
    }, [configuratorInfo, isMobile, isOptionWizard]);

    return <>
        {!hideTopHeader &&
            <TopHeader />
        }
        <ActionBar />
        <div className="article">
            <ErrorBoundary >
                <AppRoutes />
            </ErrorBoundary>

            <WaitOverlay />

            <MessageBox />
        </div>

        <div className="footer">
            <Footer />
        </div>

        <div className="stickyfooter">
            <ActionFooter />
        </div>
    </>;
}

export default AppAuthorized;