import {  AltWizActionTypeEnum } from "components/OptionsWizard/enum";
import { ISubLineItemInfo } from "components/OptionsWizard/interfaces";
import useWizardState from "components/OptionsWizard/WebDesigner/useWizardState";
import { ILineItemSimple } from "components/OptionsWizard/WizardAPI";
import { nullish, WizardModeEnum } from "components/OptionsWizard/WizardContext";
import WizardHelper from "components/OptionsWizard/WizardHelper";
import Format from "helpers/fv.format";
import useIsMobile from "helpers/hooks/useIsMobile";
import { useTranslations } from "@fenetech/translations";
import React from "react";

export default function useWizardHeaderText(odkey:number, simpleLineItem: ILineItemSimple | null): string {

    const wizardState = useWizardState();
    const tm = useTranslations();
    const isMobile = useIsMobile();

    const pageHeader = React.useMemo<string>(() => {

        if (wizardState.wizardMode === WizardModeEnum.SequencedWizard && wizardState.altWizInfo && wizardState.itemInfo && wizardState.webDesigner) {

            const altWiz = wizardState.altWizInfo;
            const itemInfo = wizardState.itemInfo;
            const webDesigner = wizardState.webDesigner;

            let sectionString = "";

            if (altWiz.selectedItems.length === 1) {
                sectionString = tm.Get("Section") + ": " + Format.FormatSubLineItem(altWiz.selectedItems[0]);
            } else if (altWiz.selectedItems.length > 1) {
                sectionString = tm.Get("Sections") + ": " + altWiz.selectedItems.map(n => Format.FormatSubLineItem(n)).sort().join(", ");
            }


            let orderString = "";

            if (itemInfo.odkey > 0)
                orderString = "#" + itemInfo.orderNumber + "-" + itemInfo.lineItem;
            else
                orderString = "#" + itemInfo.orderNumber;

            let partString:string = "";
            let sliInfo: ISubLineItemInfo | nullish;

            switch (altWiz.currentAction) {

                case AltWizActionTypeEnum.MainLineItemOptions:
                    partString = Format.FormatPartDescription(itemInfo.partNo, itemInfo.partNoSuffix);
                    return tm.Get("Main Options") + ` - ${partString} - ${orderString}`;

                case AltWizActionTypeEnum.SectionSizing:
                    partString = "";
                    sliInfo = webDesigner.sectionList.find(sli => sli.sli === altWiz.selectedItems[0]);
                    if (sliInfo) {
                        partString = Format.FormatPartDescription(sliInfo.partNo, sliInfo.partNoSuffix);
                    }

                    return tm.Get("Section Sizing") + ` - ${sectionString} - ${partString} - ${orderString}`;


                case AltWizActionTypeEnum.SectionOptions:
                    partString = "";
                    sliInfo = webDesigner.sectionList.find(sli => sli.sli === altWiz.selectedItems[0]);
                    if (sliInfo) {
                        partString = Format.FormatPartDescription(sliInfo.partNo, sliInfo.partNoSuffix);
                    }
                    return tm.Get("Section Options") + ` - ${sectionString} - ${partString} - ${orderString}`;

                case AltWizActionTypeEnum.SectionSelectPart:
                    return tm.Get("Section Part") + ` - ${sectionString} - ${orderString}`;
            }

            return sectionString;

        } else {
            if (simpleLineItem) {
                return WizardHelper.GetPageHeader(tm, odkey, simpleLineItem.partNo, simpleLineItem.partNoSuffix, simpleLineItem.orderNumber, simpleLineItem.lineItem, isMobile);
            } else {
                return "";
            }
        }

    }, [odkey, simpleLineItem, tm, isMobile, wizardState.itemInfo, wizardState.wizardMode, wizardState.altWizInfo, wizardState.webDesigner]);

    return pageHeader;

}
