import { ISaveResults, ISessionExists } from "components/OptionsWizard/interfaces";
import APIHelper from "helpers/APIHelper";

export interface ILineItemSimple {
    orderNumber: string,
    lineItem: number,
    partNo: string,
    partNoSuffix: string,
}

export default class WizardAPI {



    public static async InitExistingLineItemAsync(oKey: number, odKey: number): Promise<ISessionExists | null> {

        let url = "api/OptionsWizard/init";

        const params = { oKey, odKey };

        let wr = await APIHelper.PostAsync<ISessionExists>(url, params);

        if (wr.IsOk())
            return wr.Result;
        else
            return null;

    }

    public static async InitNewLineItemAsync(body: any): Promise<string> {

        let url = "api/OptionsWizard/initnew";

        let wr = await APIHelper.PostAsync<string>(url, undefined, body);

        return wr.Result;

    }

    public static async ClearSessionAsync(): Promise<boolean> {

        let url = "api/OptionsWizard/clear";

        let wr = await APIHelper.PostAsync(url);

        return (wr.IsOk());

    }

    public static async SaveWizardAsync(): Promise<ISaveResults> {

        let url = "api/OptionsWizard/save";

        let wr = await APIHelper.PostAsync<ISaveResults>(url);

        return wr.Result;

    }

    public static async HandleAlternatePartNo(): Promise<ISaveResults> {

        let url = "api/OptionsWizard/altPartNo";

        let wr = await APIHelper.PostAsync<ISaveResults>(url);

        return wr.Result;

    }

    public static async HandleAlternatePartYes(): Promise<ISaveResults> {

        let url = "api/OptionsWizard/altPartYes";

        let wr = await APIHelper.PostAsync<ISaveResults>(url);

        return wr.Result;

    }

    public static async GetWizardLineItemInfoSimple(): Promise<ILineItemSimple> {

        let url = "api/OptionsWizard/simple";

        let wr = await APIHelper.GetAsync<ILineItemSimple>(url);

        return wr.Result;

    }


    /* WebSauce APIs */


    public static async GetLineItemInfoSimple(okey: number, odkey: number, isOrder: boolean): Promise<ILineItemSimple> {

        let url = isOrder ? `api/orders/${okey}/Items/${odkey}/simple` : `api/quotes/${okey}/Items/${odkey}/simple`;

        //This hits the main web API
        let wr = await APIHelper.GetAsync<ILineItemSimple>(url);

        return wr.Result;

    }



}



